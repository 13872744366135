import React, { useEffect, useState } from "react";
import { NoteAdd, Home, Add, Height, Person, } from "@mui/icons-material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    Button,
    TextField,
    Box,
    Typography,
    InputAdornment,
    Divider,
    MenuItem,
    Popover,
    FormControl,
    Select,
    IconButton,
    FormHelperText,
    Grid
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import {
    calcularPorcentajeDeTotal,
    sumaDeTotales,
    recaulcularValores,
} from '../../../utilities/calculosHojasF';
const HojaFormDatosAgentes = ({
    values,
    errors,
    touched,
    categories,
    destinations,
    handleChange,
    setCustomFieldValue,
    setValues,
    sidePropOpen,
    setSidePropOpen,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const ide = open ? 'simple-popover' : undefined;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => { setAnchorEl(null); };
    const handleCustomChangeSideOpen = (event) => {
        const { value } = event.target;
        setSidePropOpen((prevState) => {
            const newState = Object.keys(prevState).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {});
            newState[value.toLowerCase()] = true;
            return newState;
        });
        setAnchorEl(null);
    };
    const handleCustomChange = (e, origin, index) => {
        const { value, name } = e.target;
        const updatedValues = { ...values };
        if (/^\d*\.?\d*$/.test(value)) {
            console.log()
            updatedValues[origin][index].porcentaje = value;
        } else {
            updatedValues[origin][index].porcentaje = "";
        }
        setValues(updatedValues);

    };
    const handleCustomBlur = (e, origin, index) => {
        const { name, value } = e.target;
        const { comisionTotal, comisionLae } = values;
        let total = calcularPorcentajeDeTotal(
            comisionLae || comisionLae > 0 ? comisionLae : comisionTotal,
            value
        );

        setValues((prevValues) => {
            const updatedValues = {
                ...prevValues,
                [origin]: prevValues[origin].map((item, idx) =>
                    idx === index
                        ? { ...item, [name]: value, total: total }
                        : item
                ),
            };

            const totalSumado = Object.keys(updatedValues)
                .filter((key) => Array.isArray(updatedValues[key]))
                .reduce((sum, key) => sum + sumaDeTotales(updatedValues[key]), 0);

            const totalComisionNeta = comisionLae
                ? comisionLae - totalSumado
                : comisionTotal - totalSumado;

            return {
                ...updatedValues,
                totalComisionNeta: totalComisionNeta,
            };
        });

    };
    const clean = (origen, index) => {
        const updatedArray = values;
        updatedArray[origen].splice(index, 1);

        const totalSumado = Object.keys(updatedArray)
            .filter((key) => Array.isArray(updatedArray[key]))
            .reduce((sum, key) => sum + sumaDeTotales(updatedArray[key]), 0);

        const { comisionLae, comisionTotal } = values;

        const totalComisionNeta = comisionLae || comisionLae > 0
            ? comisionLae - totalSumado
            : comisionTotal - totalSumado;
        setValues((prevValues) => ({
            ...prevValues,
            [origen]: updatedArray[origen],
            totalComisionNeta: totalComisionNeta,
        }));
    };
    useEffect(() => {
        recaulcularValores("participantes", values, setCustomFieldValue);
    }, [values.comisionTotal, values.opCompartida, values.comisionLae]);
    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <Typography variant="h4"
                    sx={{
                        textAlign: "left",
                        color: "#33383b",
                        marginBottom: "16px",
                    }}
                >Datos de los Agentes</Typography>
                <Button
                    variant="outline-dark"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    size="small"
                    title="vinculo"
                    sx={{
                        ...styledButton,
                        marginRight: '5px',
                        backgroundColor: '#33383b',
                        color: '#c4af6c'
                    }}
                    onClick={(e) => handleClick(e)}
                    startIcon={
                        <Box sx={{ display: 'flex', alignItems: 'center', }}>
                            <Add /> <Person />
                        </Box>
                    }
                    endIcon={<KeyboardArrowDownIcon />}
                />
                <Popover
                    id={ide}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    sx={{ '& .MuiPaper-root': { width: '250px' } }}
                >
                    <Typography sx={{ p: 2 }}>Añadir Agente:</Typography>
                    <FormControl fullWidth sx={{ padding: '25px' }}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onChange={handleCustomChangeSideOpen}
                            value={''}
                            sx={{
                                textAlign: 'left',
                                '&.MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#c4af6c',
                                    },
                                },
                            }}
                        >
                            {[
                                { name: 'AAFF', value: "AAFF" },
                                { name: 'CAPTADOR', value: 'CAPTADOR' },
                                { name: 'AGENTE VENDEDOR', value: 'AGENTE_VENDEDOR' },
                                { name: 'COLABORADOR', value: 'COLABORADOR' }
                            ].map(val => (
                                <MenuItem value={val.value}>{val.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Popover>
            </Box>
            <Box sx={{ marginTop: "20px" }}>
                {/* RESPONSABLES Y COORDINADORES */}
                {
                    values?.trabajadores_hojas?.map((trabajador, index) => {
                        if (trabajador.rol === 'RESPONSABLE') {
                            return (
                                <Box sx={boxAgentesStyle} key={index}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={7}>
                                            <TextField
                                                id="filled-helperText"
                                                size="small"
                                                placeholder="Nombre y Apellidos"
                                                label="Responsable"
                                                sx={{ ...inputStyles }}
                                                value={trabajador.nombre + " " + trabajador.apellidos}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Person sx={{ fontSize: "2em", color: "#c4af6c" }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <TextField
                                                        size="small"
                                                        placeholder="0"
                                                        name={`porcentaje`}
                                                        value={trabajador?.porcentaje ? trabajador?.porcentaje : ''}
                                                        sx={{
                                                            ...inputStyles,
                                                            maxWidth: "100px",
                                                            textAlign: "right",
                                                        }}
                                                        onChange={(e) => handleCustomChange(e, 'trabajadores_hojas', index)}
                                                        onBlur={(e) => handleCustomBlur(e, 'trabajadores_hojas', index)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">%</InputAdornment>
                                                            ),
                                                            inputProps: { style: { textAlign: "right" } },
                                                        }}
                                                        error={touched.trabajadores_hojas && Boolean(errors[`trabajadores_hojas[${index}].porcentaje`])}
                                                    />
                                                </Grid>

                                                <Grid item>
                                                    {touched.trabajadores_hojas &&  Boolean(errors[`trabajadores_hojas[${index}].porcentaje`]) && (
                                                        <FormHelperText
                                                            sx={{
                                                                textAlign: "left",
                                                                fontSize: "0.70rem",
                                                                color: "error.main",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            {errors[`trabajadores_hojas[${index}].porcentaje`]}
                                                        </FormHelperText>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>

                            )
                        } else if (trabajador.rol === 'COORDINADOR') {
                            return (
                                <Box sx={boxAgentesStyle} key={index}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={7}>
                                            <TextField
                                                id="filled-helperText"
                                                size="small"
                                                placeholder="Nombre y Apellidos"
                                                label="Coordinador"
                                                sx={{ ...inputStyles }}
                                                value={trabajador.nombre + " " + trabajador.apellidos}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Person sx={{ fontSize: "2em", color: "#c4af6c" }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <TextField
                                                        size="small"
                                                        placeholder="0"
                                                        name={`porcentaje`}
                                                        value={trabajador?.porcentaje ? trabajador?.porcentaje : ''}
                                                        sx={{
                                                            ...inputStyles,
                                                            maxWidth: "100px",
                                                            textAlign: "right",
                                                        }}
                                                        onChange={(e) => handleCustomChange(e, 'trabajadores_hojas', index)}
                                                        onBlur={(e) => handleCustomBlur(e, 'trabajadores_hojas', index)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">%</InputAdornment>
                                                            ),
                                                            inputProps: { style: { textAlign: "right" } },
                                                        }}
                                                        error={touched.trabajadores_hojas && Boolean(errors[`trabajadores_hojas[${index}].porcentaje`])}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    {touched.trabajadores_hojas && Boolean(errors[`trabajadores_hojas[${index}].porcentaje`]) && (
                                                        <FormHelperText
                                                            sx={{
                                                                textAlign: "left",
                                                                fontSize: "0.70rem",
                                                                color: "error.main",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            {errors[`trabajadores_hojas[${index}].porcentaje`]}
                                                        </FormHelperText>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )
                        }
                    })
                }
                {/* AAFF */}
                {
                    values?.participantes_hojas?.map((participante, index) => {
                        if (participante.rol === 'AAFF') {
                            return (
                                <Box sx={{ ...boxAgentesStyle }} key={index}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={7}>
                                            <TextField
                                                id="filled-helperText"
                                                size="small"
                                                placeholder="Nombre y Apellidos"
                                                label="Aaff"
                                                name="aaff"
                                                sx={{ ...inputStyles, flex: 2 }}
                                                value={participante?.razon_social ? participante?.razon_social : participante.nombre + " " + participante.apellidos}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Person sx={{ fontSize: "2em", color: "#c4af6c" }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3} sx={{ position: 'relative', }}>
                                            {/* Campo Porcentaje */}
                                            <TextField
                                                id="filled-helperText"
                                                size="small"
                                                placeholder="0"
                                                sx={{
                                                    ...inputStyles,
                                                    maxWidth: "100px",
                                                }}
                                                name={`porcentaje`}
                                                onChange={(e) => handleCustomChange(e, 'participantes_hojas', index)}
                                                onBlur={(e) => handleCustomBlur(e, 'participantes_hojas', index)}
                                                value={participante.porcentaje ? participante.porcentaje : ''}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">%</InputAdornment>
                                                    ),
                                                    inputProps: { style: { textAlign: "right" } },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton sx={styledButton} onClick={() => clean('participantes_hojas', index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            );
                        }
                    })
                }
               
                {
                    values?.trabajadores_hojas?.map((trabajador, index) => {
                        if (trabajador.rol === "CAPTADOR") {
                            return (
                                <Box sx={{ ...boxAgentesStyle,}} key={index}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={7}>
                                            <TextField
                                                id="filled-helperText"
                                                size="small"
                                                placeholder="Nombre y Apellidos"
                                                label="Captador"
                                                sx={{ ...inputStyles }}
                                                value={trabajador.nombre + " " + trabajador.apellidos}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Person sx={{ fontSize: "2em", color: "#c4af6c" }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3} sx={{ position: 'relative', }}>
                                            <TextField
                                                size="small"
                                                placeholder="0"
                                                name={`porcentaje`}
                                                value={trabajador?.porcentaje ? trabajador?.porcentaje : ''}
                                                sx={{
                                                    ...inputStyles,
                                                    maxWidth: "100px",
                                                    textAlign: "right",
                                                }}
                                                onChange={(e) => handleCustomChange(e, 'trabajadores_hojas', index)}
                                                onBlur={(e) => handleCustomBlur(e, 'trabajadores_hojas', index)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">%</InputAdornment>
                                                    ),
                                                    inputProps: { style: { textAlign: "right" } },
                                                }}
                                                error={touched.trabajadores_hojas && Boolean(errors[`trabajadores_hojas[${index}].porcentaje`])}
                                            />
                                          {touched.trabajadores_hojas &&  Boolean(errors[`trabajadores_hojas[${index}].porcentaje`]) && (
                                                <FormHelperText
                                                    sx={{
                                                        textAlign: "left",
                                                        fontSize: "0.70rem",
                                                        color: "error.main",
                                                        width: "100%",
                                                        position: 'absolute',
                                                        bottom: '-20px', // Ajusta según sea necesario
                                                    }}
                                                >
                                                    {errors[`trabajadores_hojas[${index}].porcentaje`]}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item xs={2}>
                                            {/* Botón de eliminación */}
                                            <IconButton sx={styledButton} onClick={() => clean('trabajadores_hojas', index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )
                        } else if (trabajador.rol === "AGENTE_VENDEDOR") {
                            return (
                                <Box sx={{ ...boxAgentesStyle,
                                    marginBottom: errors[`trabajadores_hojas[${index}].porcentaje`] ? '20px' : '0px'
                                 }} key={index}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={7} >
                                            <TextField
                                                id="filled-helperText"
                                                size="small"
                                                placeholder="Nombre y Apellidos"
                                                label="Vendedor"
                                                sx={{ ...inputStyles }}
                                                value={trabajador.nombre + " " + trabajador.apellidos}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Person sx={{ fontSize: "2em", color: "#c4af6c" }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3} sx={{ position: 'relative' }}>
                                            <TextField
                                                size="small"
                                                placeholder="0"
                                                name={`porcentaje`}
                                                value={trabajador?.porcentaje ? trabajador?.porcentaje : ''}
                                                sx={{
                                                    ...inputStyles,
                                                    maxWidth: "100px",
                                                    textAlign: "right",
                                                }}
                                                onChange={(e) => handleCustomChange(e, 'trabajadores_hojas', index)}
                                                onBlur={(e) => handleCustomBlur(e, 'trabajadores_hojas', index)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">%</InputAdornment>
                                                    ),
                                                    inputProps: { style: { textAlign: "right" } },
                                                }}
                                                error={Boolean(errors[`trabajadores_hojas[${index}].porcentaje`])}
                                            />
                                            {Boolean(errors[`trabajadores_hojas[${index}].porcentaje`]) && (
                                                <FormHelperText
                                                    sx={{
                                                        textAlign: "left",
                                                        fontSize: "0.70rem",
                                                        color: "error.main",
                                                        width: "100%",
                                                        position: 'absolute',
                                                        bottom: '-20px',
                                                    }}
                                                >
                                                    {errors[`trabajadores_hojas[${index}].porcentaje`]}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item xs={2}>
                                            {/* Botón de eliminación */}
                                            <IconButton sx={styledButton} onClick={() => clean('trabajadores_hojas', index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )
                        } else {
                            return <></>
                        }
                    })
                }
                {/* COLABORADORES */}
                {
                    values?.participantes_hojas?.map((participante, index) => {
                        if (participante.rol === 'COLABORADOR') {
                            return (
                                <Box sx={{ ...boxAgentesStyle, width: '80.5%' }} key={index}>
                                    <TextField
                                        id="filled-helperText"
                                        size="small"
                                        placeholder="Nombre y Apellidos"
                                        label="Colaborador"
                                        name="cola"
                                        sx={{ ...inputStyles, flex: 2 }}
                                        value={participante?.razon_social ? participante?.razon_social : participante.nombre + " " + participante.apellidos}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Person sx={{ fontSize: "2em", color: "#c4af6c" }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        id="filled-helperText"
                                        size="small"
                                        placeholder="0"
                                        sx={{
                                            ...inputStyles,
                                            flex: 1,
                                            maxWidth: "100px",
                                        }}
                                        name={`porcentaje`}
                                        onChange={(e) => handleCustomChange(e, 'participantes_hojas', index)}
                                        onBlur={(e) => handleCustomBlur(e, 'participantes_hojas', index)}
                                        value={participante.porcentaje ? participante.porcentaje : ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">%</InputAdornment>
                                            ),
                                            inputProps: { style: { textAlign: "right" } },
                                        }}
                                    />
                                    {/* <Button
                                        variant="outline-dark"
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        size="small"
                                        title="vinculo"
                                        sx={{
                                            ...styledButton,
                                            marginRight: '5px',
                                            backgroundColor: '#33383b',
                                            color: '#c4af6c'
                                        }}
                                        onClick={(e) => handleClick(e)}
                                        startIcon={
                                            <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                                <Typography sx={{ paddingLeft: '2px', fontSize: '12px !important' }}>
                                                    {vinculo === "" ? 'Vincular' : vinculo ?? formData.vinculo}
                                                </Typography>
                                            </Box>
                                        }
                                        endIcon={<KeyboardArrowDownIcon />}
                                    /> */}
                                    <IconButton sx={styledButton} onClick={() => clean('participantes_hojas', index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            );
                        }
                    })
                }
            </Box>

            <Divider
                variant="middle"
                sx={{
                    borderColor: "#c4af6c",
                    borderWidth: "1.5px",
                    marginBottom: "20px",
                    marginTop: "10px",
                    width: "80%",
                }}
            />
        </Box>
    );

};
const inputStyles = {
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#33383b !important", // Cambia el color del label cuando el TextField está seleccionado
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#c4af6c",
        },
    },
    "& .MuiInputAdornment-root": {
        color: "#333", // Color del adorno
    },
    width: "100%",
    marginBottom: 0,
    marginTop: 0,
};
const boxAgentesStyle = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    width: "80%",
    paddingBottom: "10px",
};
const styledButton = {
    backgroundColor: "#33383b",
    color: "#c4af6c",
    "&:hover": {
        backgroundColor: "#c4af6c",
        color: "#33383b",
    },
};
const iconButtonStyles = {
    height: "100%",
    backgroundColor: "#33383b",
    color: "#c4af6c",
    "&:hover": {
        backgroundColor: "#c4af6c",
        color: "#33383b",
    },
};

export default HojaFormDatosAgentes;
import React, { useContext, useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
// import Trabajadores from './components/Trabajadores';
// import Idiomas from './components/Idiomas';
// import Destinos from './components/Destinos';
// import Articulos from './components/Articulos';
// import Categorias from './components/Categorias';
// import Inicio from './components/Inicio';
import "./pages/clientes/clientes.css";
import { UserContext } from "./hooks/UserContext";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import MiniDrawer from "./components/Drawers/Drawer";
import ProtectedRoute from "./providers/ProtectedRoute";
import { useUser } from "./hooks/UserContextLogin";
// import { jwtDecode } from "jwt-decode";
import jwt_decode from "jwt-decode";
import CircularProgress from "@mui/material/CircularProgress";
import Sucursales from "./pages/sucursales/Sucursales";
import FormSucursal from "./pages/sucursales/FormSucursal";
import Trabajadores from "./pages/trabajadores/Trabajadores";
import TrabajadorForm from "./pages/trabajadores/TrabajadorForm";
import Propiedades from "./pages/propiedades/Propiedades";
import FichaPropiedad from "./pages/propiedades/FichaPropiedad";
import HojasFormalizacion from "./pages/hojaFormalizacion/hojasFormalizacion";
import HojaFormalizacion from "./pages/hojaFormalizacion/hojaFormalizacion";
import Trends from "./pages/trends/Trends";
import CreateTrend from "./pages/trends/CreateTrend";
import Categorias from "./pages/categorias/Categorias";
import CreateCategoria from "./pages/categorias/CreateCategoria";
import Idiomas from "./pages/idiomas/Idiomas";
import IdiomasForm from "./pages/idiomas/IdiomasForm";
import Destinos from "./pages/destinos/Destinos";
import { logout, verifyToken } from "./services";
import Clientes from "./pages/clientes/Clientes";
// import ClientesMenu from './pages/clientes/ClientesMenu';
import Colaboradores from "./pages/colaboradores/Colaboradores";
import Contactos from "./pages/contactos/Contactos";
import ContactosForm from "./pages/contactos/ContactosForm";
import FichaSucursal from "./pages/sucursales/FichaSucursal";
import ClientesForm from "./pages/clientes/ClientesForm";
import FichaCardContactos from "./pages/contactos/FichaCardContactos";
import AdminFincas from "./pages/aaff/AdminFincas";
import AdminFincasForm from "./pages/aaff/AdminFincasForm";
import ColaboradoresForm from "./pages/colaboradores/ColaboresForm";
import Comisiones from "./pages/comisiones/Comisiones";
import Articulos from "./pages/trends/Articulos";
import GraficosComponente from "./pages/graficos/GraficosComponente";
import ArticuloForm from "./pages/trends/ArticulosForm";
import DestinosForm from "./pages/destinos/DestinosForm";
import useTokenExpiration from "./hooks/useTokenExpiration";
import useInactivity from "./hooks/useInactivity";
import HojaFormulario from "./pages/hojaFormalizacion/HojaFormulario";

const LoadingScreen = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <CircularProgress style={{ color: "#c4af6c" }} />
  </div>
);

const AppContent = () => {
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { usuario, setUsuario } = useContext(UserContext);
  const location = useLocation();

  //Al abrir otra ventana se ejecuta esto
  useEffect(() => {
    const state = localStorage.getItem("state");
    const token = localStorage.getItem("token");
    const expires = localStorage.getItem("expires");

    if (state && token !== null && expires !== null && token !== undefined) {
      verifyTokenSaved();
      let decoded = jwt_decode(token);
      setUser(decoded);
      setLoading(false);
    } else {
      navigate("/login");
    }
  }, [setUser]);

  const verifyTokenSaved = async () => {
    const token = localStorage.getItem("token");
    try {
      const result = await verifyToken(token);
      setLoading(false);
    } catch (error) {
      console.log(error);
      navigate("/login");
    }
  };

  useTokenExpiration(logout);
  useInactivity(logout, 1800 * 1000); // 30 minutos

  // useEffect(() => {
  //   verifyTokenSaved();
  // }, [])

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Routes>
      <Route path="/" element={<MiniDrawer />}>
        <Route
          index
          element={
            <ProtectedRoute
              element={<GraficosComponente />}
              requiredPermission={{ action: "view", resource: "Graficos" }}
            />
          }
        />
        <Route
          path="Comisiones"
          element={
            <ProtectedRoute
              element={<Comisiones />}
              requiredPermission={{ action: "view", resource: "Comisiones" }}
            />
          }
        />
        <Route
          path="Trabajadores"
          element={
            <ProtectedRoute
              element={<Trabajadores />}
              requiredPermission={{ action: "view", resource: "Trabajadores" }}
            />
          }
        />
        <Route
          path="Trabajadores/form/:id?"
          element={
            <ProtectedRoute
              element={<TrabajadorForm />}
              requiredPermission={{ action: "edit", resource: "Trabajadores" }}
            />
          }
        />
        <Route
          path="Contactos"
          element={
            <ProtectedRoute
              element={<Contactos />}
              requiredPermission={{ action: "view", resource: "Contactos" }}
            />
          }
        />
        <Route
          path="Contactos/form/:id?"
          element={
            <ProtectedRoute
              element={<ContactosForm />}
              requiredPermission={{ action: "edit", resource: "Contactos" }}
            />
          }
        />
        <Route
          path="Oficinas"
          element={
            <ProtectedRoute
              element={<Sucursales />}
              requiredPermission={{ action: "view", resource: "Oficinas" }}
            />
          }
        />
        <Route
          path="Oficinas/ficha/:id?"
          element={
            <ProtectedRoute
              element={<FichaSucursal />}
              requiredPermission={{ action: "view", resource: "Oficinas" }}
            />
          }
        />
        <Route
          path="Oficinas/form/:id?"
          element={
            <ProtectedRoute
              element={<FormSucursal />}
              requiredPermission={{ action: "edit", resource: "Oficinas" }}
            />
          }
        />
        <Route
          path="Propiedades"
          element={
            <ProtectedRoute
              element={<Propiedades />}
              requiredPermission={{ action: "view", resource: "Propiedades" }}
            />
          }
        />
        <Route
          path="Propiedades/:ref"
          element={
            <ProtectedRoute
              element={<FichaPropiedad />}
              requiredPermission={{ action: "view", resource: "Propiedades" }}
            />
          }
        />
        <Route
          path="HojaDeFormalizacion"
          element={
            <ProtectedRoute
              element={<HojasFormalizacion />}
              requiredPermission={{
                action: "view",
                resource: "HojaDeFormalizacion",
              }}
            />
          }
        />
        <Route
          path="HojaDeFormalizacion/crear"
          element={
            <ProtectedRoute
              element={<HojaFormulario />}
              requiredPermission={{
                action: "create",
                resource: "HojaDeFormalizacion",
              }}
            />
          }
        />
        <Route
          path="HojaDeFormalizacion/editar/:id"
          element={
            <ProtectedRoute
              element={<HojaFormulario />}
              requiredPermission={{
                action: "edit",
                resource: "HojaDeFormalizacion",
              }}
            />
          }
        />
        <Route
          path="Articulos"
          element={
            <ProtectedRoute
              element={<Articulos />}
              requiredPermission={{ action: "view", resource: "Articulos" }}
            />
          }
        />
        <Route
          path="Articulos/form/:id?"
          element={
            <ProtectedRoute
              element={<ArticuloForm />}
              requiredPermission={{ action: "edit", resource: "Articulos" }}
            />
          }
        />
        <Route
          path="Categorias"
          element={
            <ProtectedRoute
              element={<Categorias />}
              requiredPermission={{ action: "view", resource: "Categorias" }}
            />
          }
        />
        <Route
          path="Categorias/form/:id?"
          element={
            <ProtectedRoute
              element={<CreateCategoria />}
              requiredPermission={{ action: "edit", resource: "Categorias" }}
            />
          }
        />
        <Route
          path="Idiomas"
          element={
            <ProtectedRoute
              element={<Idiomas />}
              requiredPermission={{ action: "view", resource: "Idiomas" }}
            />
          }
        />
        <Route
          path="Idiomas/form/:id?"
          element={
            <ProtectedRoute
              element={<IdiomasForm />}
              requiredPermission={{ action: "edit", resource: "Idiomas" }}
            />
          }
        />
        <Route
          path="Destinos"
          element={
            <ProtectedRoute
              element={<Destinos />}
              requiredPermission={{ action: "view", resource: "Destinos" }}
            />
          }
        />
        <Route
          path="Destinos/form/:id?"
          element={
            <ProtectedRoute
              element={<DestinosForm />}
              requiredPermission={{ action: "edit", resource: "Destinos" }}
            />
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

const App = () => <AppContent />;

export default App;

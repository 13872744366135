import React, { useEffect, useState } from "react";
import { NoteAdd, Home, Add, Height, Person, } from "@mui/icons-material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { thousandFormatNumber } from '../../../utilities/formDataHelper';
import {
    Button,
    TextField,
    Box,
    Typography,
    InputAdornment,
    Divider,
    MenuItem,
    Popover,
    FormControl,
    Select,
    IconButton
} from "@mui/material";
const HojaFormRepartos = ({
    values,
    errors,
    touched,
    categories,
    destinations,
    handleChange,
    setCustomFieldValue,
    setValues,
    sidePropOpen,
    setSidePropOpen,
}) => {
    return (
        <>
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Typography variant="h4"
                        sx={{
                            textAlign: "left",
                            color: "#33383b",
                            marginBottom: "16px",
                        }}
                    >Repartos</Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "16px" }}>
                    <Box sx={{ flex: 1, width: "50%" }}>
                        <Box sx={{ marginTop: "20px" }}>
                            {/* RESPONSABLES Y COORDINADORES */}
                            {
                                values?.trabajadores_hojas?.map((trabajador, index) => {
                                    if (trabajador.rol === 'RESPONSABLE') {
                                        return (
                                            <Box sx={{ ...boxAgentesStyle, width: "50%" }} key={index}>
                                                <TextField
                                                    id="filled-helperText"
                                                    size="small"
                                                    placeholder="0"
                                                    label={trabajador.nombre + " " + trabajador.apellidos}
                                                    sx={{ ...inputStyles, flex: 1, textAlign: 'right' }}
                                                    value={trabajador?.porcentaje ? trabajador?.porcentaje : 0}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                %
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: { style: { textAlign: "right" } },
                                                    }}
                                                />
                                                <TextField
                                                    id="filled-helperText"
                                                    size="small"
                                                    placeholder="0"
                                                    name={`total_respo_${index}`}
                                                    value={trabajador?.total ? thousandFormatNumber(trabajador?.total) : 0}
                                                    sx={{
                                                        ...inputStyles,
                                                        flex: 1,
                                                        maxWidth: "150px",
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">€</InputAdornment>
                                                        ),
                                                        inputProps: { style: { textAlign: "right" } },
                                                    }}
                                                />
                                            </Box>
                                        )
                                    } else if (trabajador.rol === 'COORDINADOR') {
                                        return (
                                            <Box sx={{ ...boxAgentesStyle, width: "50%" }} key={index}>
                                                <TextField
                                                    id="filled-helperText"
                                                    size="small"
                                                    placeholder="0"
                                                    label={trabajador.nombre + " " + trabajador.apellidos}
                                                    sx={{ ...inputStyles, flex: 1, textAlign: 'right' }}
                                                    value={trabajador?.porcentaje ? trabajador?.porcentaje : 0}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                %
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: { style: { textAlign: "right" } },
                                                    }}
                                                />
                                                <TextField
                                                    id="filled-helperText"
                                                    size="small"
                                                    placeholder="0"
                                                    name={`total`}
                                                    value={trabajador?.total ? thousandFormatNumber(trabajador?.total) : 0}
                                                    sx={{
                                                        ...inputStyles,
                                                        flex: 1,
                                                        maxWidth: "150px",
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">€</InputAdornment>
                                                        ),
                                                        inputProps: { style: { textAlign: "right" } },
                                                    }}
                                                />
                                            </Box>
                                        )
                                    }
                                })
                            }
                            {/* AAFF */}
                            {
                                values?.participantes_hojas?.map((participante, index) => {
                                    if (participante.rol === 'AAFF') {
                                        return (
                                            <Box sx={{ ...boxAgentesStyle, width: '50%' }} key={index}>
                                                <TextField
                                                    id="filled-helperText"
                                                    size="small"
                                                    label={participante.nombre + " " + participante.apellidos}
                                                    sx={{
                                                        ...inputStyles,
                                                        flex: 1,
                                                    }}
                                                    name={`porcentaje`}
                                                    value={participante?.porcentaje ? participante?.porcentaje : 0}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">%</InputAdornment>
                                                        ),
                                                        inputProps: { style: { textAlign: "right" } },
                                                    }}
                                                />
                                                <TextField
                                                    id="filled-helperText"
                                                    size="small"
                                                    placeholder="0"
                                                    name={`total`}
                                                    value={participante?.total ? thousandFormatNumber(participante?.total) : 0}
                                                    sx={{
                                                        ...inputStyles,
                                                        flex: 1,
                                                        maxWidth: "150px",
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">€</InputAdornment>
                                                        ),
                                                        inputProps: { style: { textAlign: "right" } },
                                                    }}
                                                />
                                            </Box>
                                        );
                                    }
                                })
                            }
                            {
                                values?.trabajadores_hojas?.map((trabajador, index) => {
                                    if (trabajador.rol === "CAPTADOR") {
                                        return (
                                            <Box sx={{ ...boxAgentesStyle, width: '50%' }}>
                                                <TextField
                                                    id="filled-helperText"
                                                    size="small"
                                                    placeholder="0"
                                                    label={trabajador.nombre + " " + trabajador.apellidos}
                                                    sx={{
                                                        ...inputStyles,
                                                        flex: 1,
                                                    }}
                                                    value={trabajador?.porcentaje ? trabajador?.porcentaje : 0}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">%</InputAdornment>
                                                        ),
                                                        inputProps: { style: { textAlign: "right" } },
                                                    }}
                                                />
                                                <TextField
                                                    id="filled-helperText"
                                                    size="small"
                                                    placeholder="0"
                                                    name={`total`}
                                                    value={trabajador?.total ? thousandFormatNumber(trabajador?.total) : 0}
                                                    sx={{
                                                        ...inputStyles,
                                                        flex: 1,
                                                        maxWidth: "150px",
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">€</InputAdornment>
                                                        ),
                                                        inputProps: { style: { textAlign: "right" } },
                                                    }}
                                                />

                                            </Box>
                                        )
                                    } else if (trabajador.rol === "AGENTE_VENDEDOR") {
                                        return (
                                            <Box sx={{ ...boxAgentesStyle, width: "50%" }} key={index}>
                                                <TextField
                                                    id="filled-helperText"
                                                    size="small"
                                                    placeholder="0"
                                                    label={trabajador.nombre + " " + trabajador.apellidos}
                                                    sx={{ ...inputStyles, flex: 1, textAlign: 'right' }}
                                                    value={trabajador?.porcentaje ? trabajador?.porcentaje : 0}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                %
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: { style: { textAlign: "right" } },
                                                    }}
                                                />
                                                <TextField
                                                    id="filled-helperText"
                                                    size="small"
                                                    placeholder="0"
                                                    name={`total`}
                                                    value={trabajador?.total ? thousandFormatNumber(trabajador?.total) : 0}
                                                    sx={{
                                                        ...inputStyles,
                                                        flex: 1,
                                                        maxWidth: "150px",
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">€</InputAdornment>
                                                        ),
                                                        inputProps: { style: { textAlign: "right" } },
                                                    }}
                                                />
                                            </Box>
                                        )
                                    } else {
                                        return <></>
                                    }
                                })
                            }
                            {/* COLABORADORES */}
                            {
                                values?.participantes_hojas?.map((participante, index) => {
                                    if (participante.rol === 'COLABORADOR') {
                                        return (
                                            <Box sx={{ ...boxAgentesStyle, width: '50%' }} key={index}>
                                                <TextField
                                                    id="filled-helperText"
                                                    size="small"
                                                    label={participante.nombre + " " + participante.apellidos}
                                                    sx={{
                                                        ...inputStyles,
                                                        flex: 1,
                                                    }}
                                                    name={`porcentaje`}
                                                    value={participante?.porcentaje ? participante?.porcentaje : 0}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">%</InputAdornment>
                                                        ),
                                                        inputProps: { style: { textAlign: "right" } },
                                                    }}
                                                />
                                                <TextField
                                                    id="filled-helperText"
                                                    size="small"
                                                    placeholder="0"
                                                    name={`total`}
                                                    value={participante?.total ? thousandFormatNumber(participante?.total) : 0}
                                                    sx={{
                                                        ...inputStyles,
                                                        flex: 1,
                                                        maxWidth: "150px",
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">€</InputAdornment>
                                                        ),
                                                        inputProps: { style: { textAlign: "right" } },
                                                    }}
                                                />
                                            </Box>
                                        );
                                    }
                                })
                            }
                        </Box>
                    </Box>
                    {/* TOTAL */}
                    <Box sx={{
                        flex: 1,
                        width: "40%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: "10px",
                    }}>
                        <Typography variant="h3"
                            sx={{
                                marginBottom: "16px",
                                whiteSpace: "nowrap",
                            }}
                        >
                            TOTAL NETO: <br/> {thousandFormatNumber(values.totalComisionNeta)} €
                        </Typography>
                    </Box>
                </Box>

            </Box>
        </>
    );
};
const inputStyles = {
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#33383b !important", // Cambia el color del label cuando el TextField está seleccionado
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#c4af6c",
        },
    },
    "& .MuiInputAdornment-root": {
        color: "#333", // Color del adorno
    },
    width: "100%",
    marginBottom: 0,
    marginTop: 0,
};
const boxAgentesStyle = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    width: "75%",
    paddingBottom: "10px",
};
const styledButton = {
    backgroundColor: "#33383b",
    color: "#c4af6c",
    "&:hover": {
        backgroundColor: "#c4af6c",
        color: "#33383b",
    },
};
const iconButtonStyles = {
    height: "100%",
    backgroundColor: "#33383b",
    color: "#c4af6c",
    "&:hover": {
        backgroundColor: "#c4af6c",
        color: "#33383b",
    },
};

export default HojaFormRepartos;
import React, { useEffect, useState } from "react";
import { NoteAdd, Home, Add, Height } from "@mui/icons-material";
import {
  Button,
  TextField,
  Box,
  Typography,
  InputAdornment,
  Divider,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
// import { Person, Delete, AccountCircle } from "@mui/icons-material";
import { thousandFormatNumber } from "../../../utilities/formDataHelper";
import { useCalculosHojaF } from "../../../hooks/useCalculosHojaF";
import { 
  operarPropiedad, 
  calcularPorcentajeDeTotal, 
  calcularValorPorcentual ,
  recaulcularValores
} from "../../../utilities/calculosHojasF";

const CheckboxStyles = {
  '&.Mui-checked': {
    color: '#c4af6c',
  },
  '& .MuiSvgIcon-root': {
    color: '#33383b',
    fontSize: 28
  },
  '&:hover': {
    backgroundColor: 'rgba(196, 175, 108, 0.04)',
  },
};
export default function HojaFormDatosEco({
  values,
  errors,
  touched,
  categories,
  destinations,
  handleChange,
  setCustomFieldValue,
  setValues,
  sidePropOpen,
  setSidePropOpen,
}) 
{
  const [comisionTotalAux, setComisionTotalAux] = useState(values?.comisionTotal);
  const [comisionLaeAux, setComisionLaeAux] = useState(values?.comisionLae);  
  const [isEdit, setIsEdit] = useState(false);
  const [isEditLae, setIsEditLae] = useState(false);
  const handleCustomFocus = (e) => {
    const { name, value } = e.target;
    if (name === "comisionTotal" || name === "comisionLae") {
      const parsedValue = parseFloat(value.replace(/\./g, "").replace(",", ".")) || 0;
      if (name === "comisionTotal") {
        setIsEdit(true);
        setComisionTotalAux(parsedValue);
      } else {
        setIsEditLae(true);
        setComisionLaeAux(parsedValue);
      }
    }
      // setCustomFieldValue("total", value.replace(/\./g, ""));
  };
  const handleCustomChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      if(checked){
        setCustomFieldValue(name, checked);
      } else {
        setCustomFieldValue(name, false);
        setCustomFieldValue("porLae", 0);
        setCustomFieldValue("comisionLae", 0);
        setComisionLaeAux("");
      }
      
    } else if (name === "comisionTotal") {
      const sanitizedValue = value.replace(/[^\d.,-]/g, "");
      setCustomFieldValue(name, sanitizedValue);
      setComisionTotalAux(sanitizedValue); 
    } else if(name === "comisionLae") {
      const sanitizedValue = value.replace(/[^\d.,-]/g, "");
      setCustomFieldValue(name, sanitizedValue);
      setComisionLaeAux(sanitizedValue);
    } else {
      setCustomFieldValue(name, value);
    }
  };
  const handleCustomBlur = (e) => {
    const { name, value } = e.target;
    if (name === "tipoCom") {
      const comisionTotal = calcularPorcentajeDeTotal(values.total, value);
      setCustomFieldValue("comisionTotal", comisionTotal);
      recaulcularValores("tipoCom", { ...values, comisionTotal }, setCustomFieldValue); 
    } if (name === "comisionTotal") {
      setIsEdit(false);
      const porcentaje = calcularValorPorcentual(value, values.total);
      setCustomFieldValue("tipoCom", porcentaje);
      recaulcularValores("comisionTotal", values, setCustomFieldValue); 
    } if (name === "porLae") {
      const comisionLae = calcularPorcentajeDeTotal(values.comisionTotal, value);
      setCustomFieldValue("comisionLae", comisionLae);
      setCustomFieldValue("porLae", parseFloat(value));
    } if (name === "comisionLae") {
      setIsEditLae(false);
      const porcentaje = calcularValorPorcentual(value, values.comisionTotal);
      setCustomFieldValue("porLae", porcentaje);
    } 
  }; 
  useEffect(() => {
    try {
      const totalPropiedadImporte = operarPropiedad(values.propiedades, "sumar", 0, "importe");
      setCustomFieldValue("total", totalPropiedadImporte || "");
      if (totalPropiedadImporte !== values.total) {
        setCustomFieldValue("total", totalPropiedadImporte || "");
        recaulcularValores("total", { ...values, total: totalPropiedadImporte }, setCustomFieldValue);
      }
    } catch (error) { 
      console.error("Error al calcular el total:", error.message);
      setCustomFieldValue("total", "");
    }
  }, [values.propiedades]);
  // useCalculosHojaF({
  //   array: values.propiedades, // Array de propiedades
  //   propiedad: "importe", // Propiedad a operar
  //   operacion: "sumar", // Operación: "sumar"
  //   valorExtra: 0, // Valor adicional a sumar
  //   // callbackAntes: () => console.log("Iniciando operación..."),
  //   // callbackDespues: (resultado) =>
  //   //   console.log("Operación finalizada:", resultado),
  //   actualizarResultado: (resultado) => {
  //     // Actualiza el campo "total" con el resultado
  //     console.log(resultado);

  //     setCustomFieldValue("total", (resultado == 0) ? "" : resultado);
  //   },
  // });

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "left",
            color: "#33383b",
            marginBottom: "16px",
          }}
        >
          Datos Económicos
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            marginLeft: "5px",
            marginBottom: "2px",
            textAlign: "right",
            marginTop: "2px",
          }}
        ></Box>
        {/**INPUTS*/}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { sm: "1fr 1fr 1fr" },
            gap: 1.2,
            width: "75%",
          }}
        >
          <div style={{ gridColumn: "span 1" }}>
            <TextField
              id="filled-helperText"
              size="small"
              placeholder="Total Propiedades"
              label="Total"
              sx={inputStyles}
              name="total"
              value={
                values.total
                  ? thousandFormatNumber(values.total)
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                ),
                inputProps: { style: { textAlign: "right" } }
              }}
              onChange={handleChange}
              error={touched.total && Boolean(errors.total)}
              helperText={touched.total && errors.total ? errors.total : ""}
            />
          </div>
          <div style={{ gridColumn: "span 1" }}>
            <TextField
              id="filled-helperText"
              size="small"
              placeholder="Tipo Comisión"
              label="Tipo Comisión"
              sx={inputStyles}
              name="tipoCom"
              value={values.tipoCom ?? ""}
              onChange={handleCustomChange}
              onBlur={handleCustomBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment sx={{ paddingLeft: '1%' }} position="end">%</InputAdornment>
                ),
                inputProps: { style: { textAlign: "right" } }
              }}
              error={touched.tipoCom && Boolean(errors.tipoCom)}
              helperText={touched.tipoCom && errors.tipoCom}
            />
          </div>
          <div style={{ gridColumn: "span 1" }}>
            <TextField
              id="filled-helperText"
              size="small"
              placeholder="Comisión"
              label="Comisión"
              sx={inputStyles}
              name="comisionTotal"
              value={
                isEdit ?
                  comisionTotalAux || ""
                :
                  thousandFormatNumber(values?.comisionTotal) || ""
              }
              onChange={handleCustomChange}
              onBlur={handleCustomBlur}
              onFocus={handleCustomFocus}
              InputProps={{
                endAdornment: (
                  <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                ),
                inputProps: { style: { textAlign: "right" } }
              }}
              error={touched.tipoCom && Boolean(errors.tipoCom)}
              helperText={touched.tipoCom && errors.tipoCom}
            />
          </div>
        </Box>
        <Box sx={{ display: "grid", gridTemplateColumns: { sm: "1fr 1fr" }, gap: "10px", width: "75%" }}>
          <div style={{ paddingTop: "5px", gridColumn: "span 2", display: "flex", alignItems: "center", gap: "10px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleCustomChange}
                  checked={!!values?.opCompartida}
                  sx={CheckboxStyles}
                  name="opCompartida"
                />
              }
              label={<Typography sx={{ fontSize: '13.5px' }}>OP. COMPARTIDA</Typography>}
              sx={{ marginRight: "0", width: "107%" }}
            />
            {values?.opCompartida === true && (
              <TextField
                onChange={handleChange}
                label="Porcentaje"
                size="small"
                name="porLae"
                value={values.porLae}
                onChange={handleCustomChange}
                onBlur={handleCustomBlur}
                inputProps={{ style: { textAlign: "right" } }}
                style={{ width: "100%" }}
                sx={{ ...inputStyles }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment sx={{ paddingLeft: "1%" }} position="end">%</InputAdornment>
                  ),
                }}
              />
            )}
            {values?.opCompartida === true && (
              <TextField
                label="Comisión neta"
                size="small"
                name="comisionLae"
                inputProps={{ style: { textAlign: "right" } }}
                value={
                  isEditLae ?
                    comisionLaeAux || ""
                  :
                    thousandFormatNumber(values.comisionLae) || ""
                }
                onChange={handleCustomChange}
                onBlur={handleCustomBlur}
                onFocus={handleCustomFocus}
                sx={{ ...inputStyles, }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment sx={{ paddingLeft: "1%" }} position="end">€</InputAdornment>
                  )
                }}
              />
              
            )}
          </div>
        </Box>
        <Box sx={{ display: "grid", gridTemplateColumns: { sm: "1fr" }, gap: "10px", width: "75%" }}>
          <TextField
            id="outlined-multiline-static"
            label="Observación"
            name="observacion"
            onChange={handleChange}
            value={values.observacion ?? ''}
            sx={{ ...inputStyles, width:'100%' }}
            multiline
            rows={4}
          />
        </Box>
      </Box>
      <Divider
        variant="middle"
        sx={{
          borderColor: "#c4af6c",
          borderWidth: "1.5px",
          marginBottom: "20px",
          marginTop: "10px",
          width: "80%",
        }}
      />
    </Box>
  );
};

const styledButton = {
  backgroundColor: "#33383b",
  color: "#c4af6c",
  "&:hover": {
    backgroundColor: "#c4af6c",
    color: "#33383b",
  },
};

const inputStyles = {
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#33383b !important", // Cambia el color del label cuando el TextField está seleccionado
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#c4af6c",
    },
  },
  "& .MuiInputAdornment-root": {
    color: "#333", // Color del adorno
  },
  width: "100%",
  marginBottom: 0,
  marginTop: 0,
};

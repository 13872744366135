import { useEffect, useState } from 'react';
import { Drawer, Box, Typography, Checkbox, IconButton, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DataTable from '../Tables/DataTable';
import { useAdminFincas } from '../../hooks';
import { FiltroContactosDrawer } from '../Filtros';
import { useFormValidation } from '../../utilities/formValidator';

import * as Yup from 'yup';
const CheckboxStyles = {
    '&.Mui-checked': {
        color: '#c4af6c',
    },
    '& .MuiSvgIcon-root': {
        color: '#33383b',
        fontSize: 28
    },
    '&:hover': {
        backgroundColor: 'rgba(196, 175, 108, 0.04)',
    },
};
const HojaDrawerAaff = ({
    open,
    setOpen,
    values,
    handleChange,
    setCustomFieldValue, }) => {
    const { loading, data, dataCustomNames, fetchData } = useAdminFincas();
    const [currentPage, setCurrentPage] = useState(0);
    const [filtros, setFiltros] = useState(
        
    );
    const [initialValues, setInitialValues] = useState({
        id_sucursal: values?.sucursal_raw_id,
        tipo: 'Administrador de Fincas',
        nif: '',
        buscador: ''
    });
    const headers = [
        {
            checkAaff: 'checkAaff',
            numeric: false,
            disabledPadding: false,
            label: '',
            overrideFunc: (data, row) => {
                const isChecked = values?.participantes_hojas?.some((contacto) => contacto.id === row.id);
                return (
                    <Checkbox
                        sx={CheckboxStyles}
                        onChange={(e) => addAaff(e, row)}
                        checked={isChecked}
                    />
                );
            },
        },
        {
            id: 'id',
            numeric: false,
            disabledPadding: false,
            label: 'NIF /CIF',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.numero_identificacion}
                </Typography>
            )
        },
        {
            nombre: 'nombre',
            numeric: false,
            disabledPadding: false,
            label: 'Nombre',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.nombre}
                </Typography>
            )
        },
        {
            apellidos: 'apellidos',
            numeric: false,
            disabledPadding: false,
            label: 'Apellidos',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.apellidos}
                </Typography>
            )
        },
        {
            nombreComercial: 'Nombre comercial',
            numeric: false,
            disabledPadding: false,
            label: 'R. Social',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.razon_social}
                </Typography>
            )
        },
        {
            telefono: 'telefono',
            numeric: false,
            disabledPadding: false,
            label: 'Teléfono',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.telefono}
                </Typography>
            )
        },
        {
            email: 'email',
            numeric: false,
            disabledPadding: false,
            label: 'Email',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.email}
                </Typography>
            )
        },
    ];
    const validationSchema = Yup.object({
        id_sucursal: Yup.number().nullable(),
        nif: Yup.string().notRequired().nullable(),
        buscador: Yup.string().notRequired().nullable(),
    });
    const addAaff = (e, row) => {
        const {type, checked } = e.target;
        let updatedContactos = [];
        const currentContactos = values?.participantes_hojas || []; 
        const aaff = {
            id: row.id,
            nombre: row.nombre,
            apellidos: row.apellidos,
            numero_identificacion: row.numero_identificacion,
            razon_social: row.razon_social,
            rol: 'AAFF',
            vinculo: 'NADIE',
            porcentaje:0,
        };
        if (type === 'checkbox' && checked === false) {
            updatedContactos = currentContactos.filter((contacto) => contacto.id !== row.id);
        } else {
            if (!currentContactos.some((contacto) => contacto.id === row.id)) {
                updatedContactos = [...currentContactos, aaff];
            } else {
                updatedContactos = currentContactos;
            }
            
        }
        setCustomFieldValue('participantes_hojas', updatedContactos);
    };
    useEffect(() => {
        
        if (values?.sucursal_raw_id) {
            console.log(values.sucursal_raw_id);
            setFiltros((prevState) => ({
                ...prevState,
                id_sucursal: values.sucursal_raw_id,
            }));
        }
    }, [values?.sucursal_raw_id]);
    const handleClose = () => {
        setOpen((prevState) => ({ ...prevState, aaff: false })); 
    };
    return (
        <>
            <Drawer
                anchor='right'
                open={open.aaff}
                onClose={() => handleClose()}
                sx={{ zIndex: 2000}}
            >
                <Box
                    sx={{ width: '900px', padding: 2 }}
                    role="presentation"
                    onKeyDown={() => setOpen({ ...open, aaff: false })}
                >
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="h3">Administrador de Fincas</Typography>
                        <IconButton onClick={() => setOpen({ ...open, aaff: false })}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <FiltroContactosDrawer
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        setFiltros={setFiltros}
                        fetchData={fetchData}
                        filtros={filtros}
                    />
                    <DataTable
                        loading={loading}
                        data={data}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        headers={headers}
                        extraHeader={[]}
                        setCustomFieldValue={setCustomFieldValue}
                        values={values}
                        setOpen={setOpen}
                        open={open}
                        setOpenDrawer={setOpen}
                        filtros={filtros}
                        drawer={open}
                        fetcher={fetchData}
                    />
                </Box>
            </Drawer>
        </>
    );
}

export default HojaDrawerAaff;
import { useState } from "react";
import { listDestinos } from "../services";

export const useDestinos = () => {
    const [limite, setLimite] = useState(0);
    const [data, setData] = useState({
        meta: {},
        rows: []
    });
    const [loading, setLoading] = useState(true);
    const [dataCustomNames, setDataCustomNames] = useState(null);
    const customNames = [
        'ID', 'Nombre'
    ];

    const formatData = (data) => {
        try {
          // console.log(data);          
            return data.map((row) => (              
                {
                    id: row.id,
                    destination: row.destination,
                }));

        } catch (error) {
            console.log(error);
        }

    };

    const customNameDataTable = (formattedData) => {
        // Obtener las claves del primer objeto para asignar key y label
        const keys = Object.keys(formattedData[0] || {});

        // Retornar un array de objetos con key y label
        return keys.map((key, index) => ({
            key,  // La clave original
            label: customNames[index] || key  // El nombre personalizado o la clave si no hay un nombre en customNames
        }));
    }

    const fetchData = async ({ offset, limit, filtros, orderBy, order }) => {
       
        setLimite(limit);
        let payload;
        if (orderBy && order) {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit), orderBy: `${orderBy},${order}` },
                filtros
            };
        } else {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit) },
                filtros
            };
        }

        try {
            setLoading(true);
            let response = await listDestinos(payload);
            
            const { meta, data } = response;
          
            const formattedData = formatData(data);  // Formatear los datos
            const customData = customNameDataTable(formattedData);  // Personalizar los nombres
          
            setData({
                meta,
                rows: formatData(data),
            });
            setDataCustomNames(customData);
            
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    return {
        data,
        fetchData,
        loading,
        limite,
        dataCustomNames,
    };


}

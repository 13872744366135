import React, { useState } from 'react'
import { useFormValidation } from '../../utilities/formValidator';
import * as Yup from 'yup';
import { Button, TextField, Box } from "@mui/material";
import ButtonPermission from '../../utilities/ButtonPermission';
import { useNavigate, Link } from "react-router-dom";
import { Edit, Close, Check, Delete, NoteAdd, Search, CleaningServices } from '@mui/icons-material/';
import useTrends from '../../hooks/useTrends';
import { DataTable } from '../../components';
import { dynamicTableHeader } from '../../utilities/transformHeaders';
import { SnackbarUtilities } from "../../utilities";
import AlertDialog from '../../utilities/messageConfirmation';
import ConfirmationMessage from '../../utilities/confirmationMessage';
import useDialog from '../../hooks/useDialog';
import { useIdiomas } from '../../hooks/useIdiomas';
import { languageDelete } from '../../services';
import { verificarPermisosAcciones } from '../../utilities/permissionHelper';
import { useUser } from '../../hooks';

function Idiomas() {
  const { data, fetchData, loading, dataCustomNames } = useIdiomas();
  const [dialogConfig, openDialog, closeDialog] = useDialog();
  const { user, setUser } = useUser();

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const initialValues = {
    buscador: '',
  };

  const validationSchema = Yup.object().shape({
    buscador: Yup.string().nullable(),
  });

  // Usa la función de validación personalizada
  const { values, errors, touched, handleChange, handleReset, handleBlur, handleSubmit, setCustomFieldValue, setValues } = useFormValidation({
    initialValues: initialValues,
    validationSchema: validationSchema,
    nullConverter: true,
    onSubmit: async (values) => {
      // console.log(values);
      if (values) {
        fetchData({ offset: currentPage, limit: 1000, filtros: values });
      } else {
        SnackbarUtilities.error("Error en algun validador del campo");
      }
    },
  });

  const deleteItem = async (id) => {
    try {
      const response = await languageDelete(id);
      // console.log(response);
      if (response.status == 200) {
        SnackbarUtilities.success("Idioma eliminado correctamente!");
        fetchData({ offset: currentPage, limit: 1000 });
        // Aquí puedes agregar cualquier lógica adicional, como actualizar la lista de trabajadores
      } else {
        SnackbarUtilities.error("Error al eliminar el Idioma. Inténtalo de nuevo más tarde.");
      }
    } catch (error) {
      console.log(error);
    }
  }

//   onClick = {() => openDialog({
//     title: "Confirmación de eliminación",
//     description: `¿Está seguro de que quiere eliminar el elemento con titulo:  ${name.title} ?`,
//     onClickOk: () => deleteItem(name.id)
//   })
// }

const iconColumn = [
  {
    item: (name) => {
      // console.log(name)
      return (
        <div style={{ display: "flex", justifyContent: "left" }}>
          {verificarPermisosAcciones(['edit', 'delete', 'all'], user, 'Idiomas', null, name, ()=>openDialog({
            title: "Confirmación de eliminación",
            description: `¿Está seguro de que quiere eliminar el elemento con titulo:  ${name.idiomas} ?`,
            onClickOk: () => deleteItem(name.id)
          }), "/Idiomas/form/")}
        </div>
      )
    },
    position: -1,
    titleHeader: "Acciones",
    nameColumn: "",
    order: "disable",
  }
];

return (
  <div className="container">
    <div className="topline">
      <div>
        <h3>Idiomas</h3>
      </div>
      <div>
        <ButtonPermission onClick={() => { navigate("/Idiomas/form") }} variant="contained" endIcon={<NoteAdd />}
          action="create"
          resource="Idiomas"
          sx={{
            color: '#c4af6c',
            backgroundColor: '#33383b',
            height: 40,
            marginBottom: 1,
            borderColor: 'transparent',
            '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
          }}>
          Crear
        </ButtonPermission>
      </div>
      {
        (process.env.NODE_ENV == "development" && Object.keys(errors).length > 0) &&
        <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
          {JSON.stringify(errors)}
        </div>
      }
    </div>
    <form onSubmit={handleSubmit}>

      <div className="middleline">
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { sm: '1fr 1fr' },
              gap: 2,
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between", gap: 6 }}>
              <TextField
                sx={inputStyles}
                label="Buscador"
                name="buscador"
                size="small"
                margin="normal"
                value={values.buscador}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.buscador && Boolean(errors.buscador)}
                helperText={touched.buscador && errors.buscador}
              />
            </div>
            <div style={{ display: "flex", flex: 0.5, justifyContent: "start", gap: '10px' }}>

              <Button
                type="submit"
                variant="outlined"
                sx={{
                  color: '#c4af6c',
                  backgroundColor: '#33383b',
                  height: 40,
                  borderColor: 'transparent',
                  '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                }}
              >
                <Search style={{ color: '#c4af6c', fontSize: "1.3rem", '&:hover': { color: '#33383b' } }} />
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: '#33383b',
                  backgroundColor: '#c4af6c',
                  borderColor: 'transparent',
                  '&:hover': { color: '#c4af6c', backgroundColor: '#fff', borderColor: '#33383b', }
                }}
                onClick={() => {
                  fetchData({ offset: currentPage, limit: 1000 });
                  handleReset();
                }}                        >
                <CleaningServices style={{ color: '#33383b', fontSize: "1.3rem", '&:hover': { color: '#c4af6c' } }} />
              </Button>
            </div>
          </Box>

        </div>

      </div>

    </form>
    <DataTable
      data={data}
      headers={dynamicTableHeader({
        headerArrayData: dataCustomNames,
        showJustThis: ['ID', 'Nombre', 'Código ISO'],
        addNewCustomHeader: iconColumn
      })}
      chickable={false}
      isLoading={loading}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      fetcher={fetchData}
      mensaje={"No hay idiomas disponibles"}
    />
    <ConfirmationMessage
      {...dialogConfig}
      onClose={closeDialog}
    />
  </div>
);
}
const inputStyles = {
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#c4af6c',
    },
  },
  '& .MuiInputAdornment-root': {
    color: '#333', // Color del adorno
  },
  width: "100%",
  marginBottom: 0,
  marginTop: 0
};
export default Idiomas;

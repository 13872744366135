export const createFormData = (data, formData = new FormData(), parentKey = '') => {

    const jsonData = {}; // Objeto para almacenar los datos que no son archivos

    // Iteramos sobre las propiedades del objeto `data`
    Object.entries(data).forEach(([key, value]) => {
        if (value instanceof File) {
            // Si el valor es un archivo, lo añadimos directamente al FormData
            formData.append(key, value);
        } else {
            // Si no es un archivo, lo añadimos al objeto jsonData
            jsonData[key] = value;
        }
    });

    // Añadimos los datos como un único campo JSON en el FormData
    formData.append('data', JSON.stringify(jsonData));

    // // Para depurar: Imprimimos el FormData
    // for (let pair of formData.entries()) {
    //     console.log(pair[0] + ': ' + pair[1]);
    // }

    return formData;
}

//convert number to deutsch format 11.000.000,12
export const thousandFormatNumber = (number, simbol = null) => {
    const regexFormatted = /^\d{1,3}(\.\d{3})*,\d{2}$/;
    if (typeof number === "string" && regexFormatted.test(number)) {
        return simbol ? `${number} €` : number; // Retornar directamente si ya tiene el formato deseado
    }

    if (typeof number !== 'number') {
        number = parseFloat(number);
        if (isNaN(number)) {
            return "";
        }
    }
    if (number === null || number === undefined) {
        return "";
    }
    const formattedNumber = number.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return simbol ? `${formattedNumber} €` : formattedNumber;
}
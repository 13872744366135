import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Autocomplete,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Radio,
} from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { getCurrentDate } from "../../../utilities";
import { formatDate } from "../../../utilities/dateFormat"
import { generateRef, listSucursales, verifyReferencia } from "../../../services";
import { useParams } from "react-router-dom";

export default function HojaFormOficina({
  editMode,
  values,
  errors,
  touched,
  categories,
  destinations,
  handleChange,
  setCustomFieldValue,
  setValues,
}) {

  const [sucursalMap, setSucursalMap] = useState(null);
  const { id } = useParams();
  const fetchSucursales = async () => {
    const response = await listSucursales({
      offset: 1,
      limit: 50,
      filtros: { no_off_central: 10 },
      // params: {
      //   orderBy: "nombre_sucursal,ASC",
      // },
    });
    setSucursalMap(response.data || []);
  };
  const formatDateForInput = (date) => {
    if (!date) return "";
    const parsedDate = new Date(date);
    if (isNaN(parsedDate)) return "";
    return parsedDate.toISOString().split("T")[0];
  };
  useEffect(() => {
    fetchSucursales();
  }, []);
  const handleCustomChange = async (event) => {
    const { sucursal_trabajadores } = event;
    let trabajadores_hojas = sucursal_trabajadores
    .filter(trbj =>
      trbj.active === true &&
      trbj.user_trabajador?.role &&
      ['COOR', 'RDOF'].includes(trbj.user_trabajador.role.code)
    )
    .map(({ id, nombre, apellidos, user_trabajador }) => ({
      id_trabajador: id,
      nombre,
      apellidos,
      vinculo: 'NADIE',
      rol: user_trabajador?.role?.code === 'RDOF' ? 'RESPONSABLE' : 'COORDINADOR',
    }));
    setCustomFieldValue("trabajadores_hojas", trabajadores_hojas)
    setCustomFieldValue("id_sucursal", event.id_ext);
    setCustomFieldValue("sucursal_raw_id", event.id);
  };
  const handleCustomChangeDate = (e) => {
    const { value } = e.target;
    setCustomFieldValue('fechaOperacion', value);
  };
  const handleCustomBlur = async (e) => {
    const { value } = e.target;
    const {sucursal_raw_id} = values;
    const anyo = value.split('-')[0];
    let data = { id_sucursal: sucursal_raw_id, anyo}
    try {
      let newReferencia = await verifyReferencia(data);
      console.log(newReferencia)
      setCustomFieldValue('referencia', newReferencia);
    } catch (error) {
      console.log(error);
    }
    
  }
  console.log(sucursalMap)
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { sm: "1fr 1fr 1fr 1fr 1fr " },
        gap: 2,
        marginBottom: "1%"
      }}
    >
      {sucursalMap && !editMode && (
        <div style={{ gridColumn: "span 6" }}>
          <Autocomplete
            value={sucursalMap.find((item) => item.id === values.id)}
            onChange={(event, newValue) => {
              handleCustomChange(newValue);
            }}
            autoHighlight
            // onInputChange={(event, newInputValue) => {
            //   setInputValue(newInputValue);
            // }}
            id="controllable-states-demo"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={[...sucursalMap].sort((a, b) => a.nombre_sucursal.localeCompare(b.nombre_sucursal))}
            getOptionLabel={(option) => `LAE HOMES - ${option?.municipio}`}
            //   sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Elige una Oficina"
                error={touched.id && Boolean(errors.id)}
                helperText={touched.id && errors.id}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FaSearch />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    ...inputStyles,
                    color: "#33383b", // Color del texto
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#33383b", // Color del label cuando está enfocado
                  },
                  "& .MuiOutlinedInput-root": {
                    ...inputStyles,
                    borderColor: "#c4af6c", // Color del borde
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#c4af6c", // Color del borde cuando está enfocado
                    },
                  },
                }}
              />
            )}
          />
        </div>
      )}
      {id ? 
        <div style={{ gridColumn: "span 1" }}>
         <TextField
           onChange={handleChange}
           id="filled-helperText"
           size="small"
           name="referencia"
           disabled
           value={values?.referencia}
           placeholder="Referencia"
           sx={inputStyles}
         />
       </div>
       :
       <></>
      }
     
      <div style={{ gridColumn: "span 1" }}>
        <TextField
          sx={inputStyles}
          size="small"
          label="Fecha de Operación"
          type="date"
          name="fechaOperacion"
          value={editMode ? formatDateForInput(values.fechaOperacion) : values.fechaOperacion}
          onChange={handleCustomChangeDate}
          onBlur={handleCustomBlur}
          InputLabelProps={{ shrink: true }}
        />
      </div>
      <div style={{ gridColumn: "span 5" }}>
        <FormControl
          component="fieldset"
          error={touched.accion && Boolean(errors.accion)}
          style={{ width: "100%" }}
        >
          <RadioGroup
            name="accion"
            row
            onChange={handleChange}
            value={values.accion}
          >
            <FormControlLabel
              value="ALQUILER"
              control={<Radio sx={CheckboxStyles} />}
              label="ALQUILER"
            />
            <FormControlLabel
              value="COMPRA_VENTA"
              control={<Radio sx={CheckboxStyles} />}
              label="COMPRA - VENTA"
            />
            <FormControlLabel
              value="TRASPASO"
              control={<Radio sx={CheckboxStyles} />}
              label="TRASPASO"
            />
            <FormControlLabel
              value="ALQ_OPCION_COMPRA"
              control={<Radio sx={CheckboxStyles} />}
              label="ALQ. OPCIÓN COMPRA"
            />
          </RadioGroup>
          {touched.accion && errors.accion && (
            <FormHelperText>{errors.accion}</FormHelperText>
          )}
        </FormControl>

      </div>
    </Box>
  );
}
const inputStyles = {
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#33383b !important", // Cambia el color del label cuando el TextField está seleccionado
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#c4af6c",
    },
  },
  "& .MuiInputAdornment-root": {
    color: "#333", // Color del adorno
  },
  width: "100%",
  marginBottom: 0,
  marginTop: 0,
};
const CheckboxStyles = {
  "&.Mui-checked": {
    color: "#c4af6c",
  },
  "& .MuiSvgIcon-root": {
    color: "#33383b",
    fontSize: 28,
  },
  "&:hover": {
    backgroundColor: "rgba(196, 175, 108, 0.04)",
  },
};

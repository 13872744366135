import * as React from "react";
import { usePropiedades } from "../../hooks";
import { useEffect, useState } from "react";
import {
  Drawer,
  Box,
  Typography,
  Checkbox,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DataTable from "../Tables/DataTable";
import SearchIcon from "@mui/icons-material/Search";
import { operarPropiedad } from "../../utilities/calculosHojasF";

const HojaDrawerPropiedades = ({
  open,
  setOpen,
  values,
  propiedades = [],
  setCustomFieldValue,
}) => {
  const { loading, data, fetchData } = usePropiedades();
  const [currentPage, setCurrentPage] = useState(1);
  const [filtros, setFiltros] = useState({
    sucursal: values.id_sucursal || "",
  });

  const headers = [
    {
      numeric: false,
      disablePadding: false,
      label: "",
      overrideFunc: (data, row) => (
        <>
          <Checkbox
            sx={CheckboxStyles}
            onChange={(e) => {
              handleCustomChange(e, row);
            }}
            checked={isCheckched(values.propiedades, row.id)}
          />
        </>
      ),
    },
    {
      provincia: "referencia",
      numeric: false,
      disablePadding: false,
      label: "Referencia",
      overrideFunc: (data, row) => <Typography>{row?.referencia}</Typography>,
    },
    {
      provincia: "provincia",
      numeric: false,
      disablePadding: false,
      label: "Provincia",
      overrideFunc: (data, row) => <Typography>{row?.provincia}</Typography>,
    },
    {
      municipio: "municipio",
      numeric: false,
      disablePadding: false,
      label: "Municipio",
      overrideFunc: (data, row) => <Typography>{row?.ciudad}</Typography>,
    },
  ];

  const handleCustomChange = (event, row) => {
    const { name, value, type, checked } = event.target;
    // console.log(name, value, type, checked);
    // console.log(row);
    const {
      id,
      direccion,
      sucursal_municipio,
      provincia,
      cp,
      promocion,
      precioinmo,
      ...rest
    } = row;

    if (type == "checkbox" && checked == false) {
      //Eliminar una propiedad quitando el check
      setCustomFieldValue(
        "propiedades",
        values.propiedades.filter((prop) => prop.id_prop !== row.id)
      );
      // console.log(operarPropiedad( values.propiedades.filter((prop) => prop.id_prop !== row.id),"sumar",0,"importe"));
      // setCustomFieldValue("total",operarPropiedad( values.propiedades.filter((prop) => prop.id_prop !== row.id),"sumar",0,"importe"));
    } else {
      //Añadir una propiedad poniendo el check
      setCustomFieldValue("propiedades", [
        ...values.propiedades,
        {
          id_prop: id,
          direccion: "",
          municipio: sucursal_municipio,
          provincia: provincia,
          cp: cp,
          promocion: "",
          importe: precioinmo,
        },
      ]);
      // setCustomFieldValue("total",operarPropiedad(values.propiedades,"sumar",precioinmo,"importe"));
      // console.log(operarPropiedad(values.propiedades,"sumar",precioinmo,"importe"));
      
      // setCustomFieldValue("total",)
    }
  };

  const isCheckched = (propiedades, elId) => {
    return propiedades.some(
      (prop) => prop.id === elId || prop.id_prop === elId
    );
  };
  const asignarPropiedad = (e, prop) => {
    const { checked, value } = e.target;
    if (prop == "") {
      setCustomFieldValue("propiedades", [
        ...values.propiedades,
        {
          id_prop: "",
          direccion: "",
          municipio: "",
          provincia: "",
          cp: "",
          promocion: "",
          importe: "",
        },
      ]);
      handleClose();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFiltros((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (values.id_sucursal) {
      setFiltros((prevState) => ({
        ...prevState,
        sucursal: values.id_sucursal,
      }));
    }
  }, [values.id_sucursal]);

  const searchPropiedad = async () => {
    await fetchData({ offset: currentPage, limit: 1000, filtros: filtros });
  };

  const handleClose = () => {
    setOpen((prevState) => ({ ...prevState, propiedades: false })); // Actualiza el estado de forma inmutable
  };

  return (
    <Drawer
      anchor="right"
      open={open.propiedades}
      onClose={handleClose}
      sx={{ zIndex: 2000 }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <div className="container">
        <Box component="section" sx={{ p: 3 }}>
          <Typography variant="h3">Propiedades</Typography>
          <Typography variant="p">Debes Seleccionar una propiedad</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 3,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <TextField
              label="Referencia"
              name="referencia"
              size="small"
              onChange={handleChange}
              sx={{
                ...inputStyles,
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#33383b",
                },
              }}
            />
            <Button
              sx={styledButton}
              variant="outlined"
              onClick={() => searchPropiedad()}
            >
              <SearchIcon />
            </Button>
          </Box>
          <Button
            sx={styledButton}
            variant="outline-dark"
            onClick={(e) => {
              asignarPropiedad(e, "");
            }}
          >
            añadir nueva propiedad vacía
          </Button>
        </Box>
      </div>
      <div className="middleline">
        {values.id_sucursal ? (
          <DataTable
            headers={headers}
            data={data}
            isLoading={loading}
            fetcher={fetchData}
            mensaje={"No hay propiedades disponibles"}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            filtros={filtros}
            // properties={properties}
          />
        ) : (
          <Typography>Debes seleccionar una sucursal</Typography>
        )}
      </div>
    </Drawer>
  );
};

const CheckboxStyles = {
  "&.Mui-checked": {
    color: "#c4af6c",
  },
  "& .MuiSvgIcon-root": {
    color: "#33383b",
    fontSize: 28,
  },
  "&:hover": {
    backgroundColor: "rgba(196, 175, 108, 0.04)",
  },
};
const styledButton = {
  backgroundColor: "#33383b",
  color: "#c4af6c",
  "&:hover": {
    backgroundColor: "#c4af6c",
    color: "#33383b",
  },
};
const inputStyles = {
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#33383b !important", // Cambia el color del label cuando el TextField está seleccionado
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#c4af6c",
    },
  },
  "& .MuiInputAdornment-root": {
    color: "#333", // Color del adorno
  },
};

export default HojaDrawerPropiedades;

import React, { useState, useEffect } from "react";
import { NoteAdd, Home, Add } from "@mui/icons-material";
import {
  Button,
  TextField,
  Box,
  Typography,
  InputAdornment,
  Divider,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { thousandFormatNumber } from "../../../utilities/formDataHelper";
import { formatNumbers } from "../../../utilities/formValidator";
import { Delete } from "@mui/icons-material";
import { normalizarProvincias } from "../../../utilities/validator-textos";
import provinces from "@doncicuto/es-provinces";

export default function HojaFormPropiedades({
  values,
  errors,
  touched,
  categories,
  destinations,
  handleChange,
  setCustomFieldValue,
  setValues,
  sidePropOpen,
  setSidePropOpen,
}) {
  const [auxPropiedades, setAuxpropiedades] = useState(values?.propiedades || []);
  const [propsEditing, setPropsEditing] = useState(
    values?.propiedades.map(() => false)|| []
  );
  const [loadValues, setLoadValues] = useState(true);
  useEffect(() => {
    if (values?.propiedades || loadValues) {
      setPropsEditing(values.propiedades.map(() => false));
      const updatedValues = [...values.propiedades];
      updatedValues.forEach((item, index) => {
        if (typeof item.importe === 'string' && item.importe.includes(",")) {
          updatedValues[index].importe = parseFloat(item.importe.replace(/\./g, "").replace(",", "."));
        }
      });
      
      setAuxpropiedades(updatedValues);
      setLoadValues(false);
    }
  }, [loadValues, values?.propiedades, ]);
  const handleCustomChange = (event, index) => {
    const { value } = event.target;
    const updatedValues = [...values.propiedades];
    // if(propsEditing[index]){
      updatedValues[index].importe = value;
      handleChange(event);
      setCustomFieldValue("propiedades", updatedValues);
      setValues((prevValues) => ({  ...prevValues, propiedades: updatedValues }));
      setPropsEditing((prevPropsEditing) =>
        prevPropsEditing.map((item, i) => (i === index ? true : item))
      );
    // }
    
    
  };
  const handleCustomFocus = (event, index) => {
    const { propiedades } = values;
    const updatedValues = [...propiedades];
    setPropsEditing((prevPropsEditing) =>
      prevPropsEditing.map((item, i) => (i === index ? true : item))
    );
    let mi_importe = updatedValues[index]?.importe ?? "";
    
    if (typeof mi_importe === "string" && mi_importe.includes(",")) {
      mi_importe = parseFloat(mi_importe.replace(/\./g, "").replace(",", "."));
    }

    updatedValues[index].importe = mi_importe ;
    setAuxpropiedades(updatedValues);
   
  };

  const handleCustomBlur = (event, index) => {
    const { importe } = auxPropiedades[index] ? auxPropiedades[index] : "";
    const updatedValues = [...values.propiedades];
  
    if (!isNaN(importe) && importe !== null) {
      setAuxpropiedades((prevAuxPropiedades) => {
        const newAuxPropiedades = [...prevAuxPropiedades];
        const formattedImporte = parseFloat(importe).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        if (importe !== formattedImporte) {
          newAuxPropiedades[index].importe = formattedImporte;
        } else {
          newAuxPropiedades[index].importe = importe;
        }
        return newAuxPropiedades;
      });
    }else {
      setAuxpropiedades((prevAuxPropiedades) => {
        const newAuxPropiedades = [...prevAuxPropiedades];
        newAuxPropiedades[index].importe = "";
        return newAuxPropiedades;  
      });
      updatedValues[index].importe = "";
      // setCustomFieldValue("propiedades", updatedValues);
    }
    setPropsEditing((prevPropsEditing) =>
      prevPropsEditing.map((item, i) => (i === index ? false : item))
    );
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "left",
            color: "#33383b",
            marginBottom: "16px",
          }}
        >
          {values?.propiedades.length > 1 ? 'Propiedades': 'Propiedad'}
        </Typography>
        <Button
          variant="contained"
          sx={styledButton}
          onClick={() => {
            setSidePropOpen((prevState) => ({
              ...prevState,
              propiedades: true,
            }));
          }}
          startIcon={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Add />
              <Home />
            </Box>
          }
        />
      </Box>
      {values?.propiedades.map((item, index) => {
        return (
          <Box key={index}> 
            <Box
              sx={{
                marginLeft: "5px",
                marginBottom: "2px",
                textAlign: "right",
                marginTop: "4px",
              }}
            ></Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { sm: "1fr 1fr 1fr 1fr" },
                gap: 1.2,
                width: "95%",
              }}
            >
              <div style={{ gridColumn: "span 2" }}>
                <TextField
                  id="filled-helperText"
                  size="small"
                  placeholder="Dirección"
                  label="Dirección"
                  sx={inputStyles}
                  name={`propiedades[${index}].direccion`}
                  value={values.propiedades[index].direccion ?? ""}
                  onChange={handleChange}
                  error={
                    touched?.propiedades &&
                    Boolean(errors[`propiedades[${index}].direccion`])
                  }
                  helperText={ touched?.propiedades &&
                    Boolean(errors[`propiedades[${index}].direccion`]) ? errors[`propiedades[${index}].direccion`] :''}
                />
              </div>
              <div style={{ gridColumn: "span 1" }}>
                <TextField
                  id="demo-simple-select-label"
                  select
                  label="Provincia"
                  margin="normal"
                  sx={{ ...inputStyles }}
                  size="small"
                  name={`propiedades[${index}].provincia`}
                  value={normalizarProvincias(
                    values.propiedades[index]?.provincia
                  )}
                  error={
                    touched?.propiedades &&
                    Boolean(errors[`propiedades[${index}].provincia`])
                  }
                  helperText={ touched?.propiedades &&
                    Boolean(errors[`propiedades[${index}].provincia`]) ? errors[`propiedades[${index}].provincia`] :''
                  }
                  onChange={handleChange}
                >
                  {provinces && (
                    <MenuItem value="">
                      <em>Ninguna</em>
                    </MenuItem>
                  )}
                  {provinces
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item, index) => (
                      <MenuItem key={index} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                </TextField>
              </div>
              <div style={{ gridColumn: "span 1" }}>
                <TextField
                  id="filled-helperText"
                  size="small"
                  placeholder="Municipio"
                  label="Municipio"
                  sx={inputStyles}
                  name={`propiedades[${index}].municipio`}
                  value={values.propiedades[index]?.municipio ?? ""}
                  onChange={handleChange}
                  error={
                    touched?.propiedades &&
                    Boolean(errors[`propiedades[${index}].municipio`])
                  }
                  helperText={ touched?.propiedades &&
                    Boolean(errors[`propiedades[${index}].municipio`]) ? errors[`propiedades[${index}].municipio`] :''
                  }
                />
              </div>
              <div>
                <TextField
                  id="filled-helperText"
                  size="small"
                  placeholder="CP"
                  label="CP"
                  sx={inputStyles}
                  name={`propiedades[${index}].cp`}
                  value={values.propiedades[index].cp}
                  onChange={handleChange}
                />
              </div>
              <div>
                <TextField
                  id="filled-helperText"
                  size="small"
                  placeholder="Promoción"
                  sx={inputStyles}
                  name={`propiedades[${index}].promocion`}
                  value={values.propiedades[index].promocion}
                  onChange={handleChange}
                />
              </div>
              <div>
                <TextField
                  id="filled-helperText"
                  size="small"
                  placeholder="Importe"
                  sx={inputStyles}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment sx={{ paddingLeft: "1%" }} position="end">
                        €
                      </InputAdornment>
                    ),
                  }}

                  inputProps={{ style: { textAlign: "right" } }}
                  name={`propiedades[${index}].importe`}
                  value={
                    !propsEditing[index] 
                      ? 
                     auxPropiedades[index]?.importe
                      : values.propiedades[index]?.importe
                  }
                  error={
                    touched?.propiedades &&
                    Boolean(errors[`propiedades[${index}].importe`])
                  }
                  helperText={ touched?.propiedades &&
                    Boolean(errors[`propiedades[${index}].importe`]) ? errors[`propiedades[${index}].importe`] :''
                  }
                  onChange={(e) => handleCustomChange(e, index)}
                  onBlur={(e) => handleCustomBlur(e, index)}
                  onFocus={(e) => handleCustomFocus(e, index)}
                />
              </div>

              <div style={{ gridColumn: "span 1" }}>
                <Button
                  sx={{ ...iconButtonStyles }}
                  onClick={() => {
                    setCustomFieldValue(
                      "propiedades",
                      values.propiedades.filter((_, i) => i !== index)
                    );
                    setAuxpropiedades( auxPropiedades.filter((_, i) => i !== index));
                  }}
                  size="small"
                >
                  <Delete />
                </Button>
              </div>
            </Box>

            {index < values.propiedades.length && (
              <Divider
                variant="middle"
                sx={{
                  borderColor: "#c4af6c",
                  borderWidth: "1.5px",
                  marginBottom: "20px",
                  marginTop: "10px",
                  width: "80%",
                }}
              />
            )}
          </Box>
        );
      })}
      {touched.propiedades == 0 && errors.propiedades && (
        <FormHelperText sx={{ color: "#d32f2f" }}>{errors.propiedades}</FormHelperText>
      )}
      {values.propiedades.length == 0 && (
        <Divider
          variant="middle"
          sx={{
            borderColor: "#c4af6c",
            borderWidth: "1.5px",
            marginBottom: "10px",
            marginTop: "40px",
            width: "80%",
          }}
        />
      )}
    </Box>
  );
}
const inputStyles = {
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#33383b !important", // Cambia el color del label cuando el TextField está seleccionado
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#c4af6c",
    },
  },
  "& .MuiInputAdornment-root": {
    color: "#333", // Color del adorno
  },
  width: "100%",
  marginBottom: 0,
  marginTop: 0,
};
const styledButton = {
  backgroundColor: "#33383b",
  color: "#c4af6c",
  "&:hover": {
    backgroundColor: "#c4af6c",
    color: "#33383b",
  },
};
const iconButtonStyles = {
  height: "100%",
  backgroundColor: "#33383b",
  color: "#c4af6c",
  "&:hover": {
    backgroundColor: "#c4af6c",
    color: "#33383b",
  },
};

import { useState, useEffect } from "react";
import { Drawer, Box, Typography, Checkbox, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DataTable from "../Tables/DataTable";
import { FiltroTrabajadoresDrawer } from "../Filtros";
import { useFormValidation } from "../../utilities/formValidator";
import { useTrabajadores } from "../../hooks";
import * as Yup from "yup";
import { dynamicTableHeader } from "../../utilities/transformHeaders";

const CheckboxStyles = {
    '&.Mui-checked': {
        color: '#c4af6c',
    },
    '& .MuiSvgIcon-root': {
        color: '#33383b',
        fontSize: 28
    },
    '&:hover': {
        backgroundColor: 'rgba(196, 175, 108, 0.04)',
    },
};

const HojaDrawerTrabajadores = ({
    open,
    setOpen,
    values,
    handleChange,
    setCustomFieldValue,
}) => {
    const { loading, data, fetchData } = useTrabajadores();
    const [currentPage, setCurrentPage] = useState(1);
    const [filtros, setFiltros] = useState({ sucursal_id: values?.sucursal_raw_id, active:true });
    const [initialValues, setInitialValues] = useState({
        sucursal_id: null,
        buscador: '',
        active: true,
    });

    const validationSchema = Yup.object().shape({
        sucursal_id: Yup.string().nullable(),
        buscador: Yup.string().nullable(),
        active: Yup.boolean().nullable(),
    });
    const headers = [
        {
            checkCli: 'checkCli',
            numeric: false,
            disabledPadding: false,
            label: '',
            overrideFunc: (data, row) => {
                const isChecked = values?.trabajadores_hojas?.some(
                    (trabajador) =>
                        trabajador.id_trabajador === row.id &&
                        ((open.captador && trabajador.rol === 'CAPTADOR') ||
                        (open.agente_vendedor && trabajador.rol === 'AGENTE_VENDEDOR'))
                );
                return (
                    <Checkbox
                        sx={CheckboxStyles}
                        onChange={(e) => addTrabajador(e, row)}
                        checked={isChecked}
                    />
                );
            },
        },
        {
            nombre: 'nombre',
            numeric: false,
            disabledPadding: false,
            label: 'Nombre',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.nombre}
                </Typography>
            )
        },
        {
            apellidos: 'apellidos',
            numeric: false,
            disabledPadding: false,
            label: 'Apellidos',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.apellidos}
                </Typography>
            )
        },
        {
            telefono: 'telefono',
            numeric: false,
            disabledPadding: false,
            label: 'Teléfono',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.telefono}
                </Typography>
            )
        },
        {
            email: 'email',
            numeric: false,
            disabledPadding: false,
            label: 'Email',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.email}
                </Typography>
            )
        },
    ];

    useEffect(() => {
        if (values?.sucursal_raw_id) {
            setFiltros((prevState) => ({
                ...prevState,
                sucursal_id: values.sucursal_raw_id,
            }));
        }
    }, [values?.sucursal_raw_id]);
    const handleClose = () => {
        setOpen((prevState) => ({
            ...prevState,
            captador: prevState.captador ? false : prevState.captador,
            agente_vendedor: !prevState.captador ? false : prevState.agente_vendedor,
        }));
    };
    const addTrabajador = (e, row) => {
        const {type, checked } = e.target;
        let updatedTrabajadores = [];
        const currentTrabajadores = values?.trabajadores_hojas || []; 
        const openKeys = Object.keys(open); 
        let rol = "";
        openKeys.forEach((key) => {
            if (key === "captador" && open[key]) {
                rol = "CAPTADOR";
            } else if (key === "agente_vendedor" && open[key]) {
                rol = "AGENTE_VENDEDOR";
            }
        });
        const trabajador = {
            id_trabajador: row.id,
            nombre: row.nombre,
            apellidos: row.apellidos,
            vinculo: 'NADIE',
            rol:rol
        };
        if (type === "checkbox" && !checked) {
            updatedTrabajadores = currentTrabajadores.filter(
                (trb) => !(trb.id === row.id && trb.rol === rol)
            );
        } else {
            const existeMismoRol = currentTrabajadores.some(
                (trb) => trb.id === row.id && trb.rol === rol
            );
    
            if (!existeMismoRol) {
                updatedTrabajadores = [...currentTrabajadores, trabajador];
            } else {
                updatedTrabajadores = currentTrabajadores;
            }
        }
        setCustomFieldValue('trabajadores_hojas', updatedTrabajadores);

    }
    return (
        <>
            <Drawer
                anchor='right'
                open={open.captador || open.agente_vendedor}
                onClose={() => handleClose()}
                sx={{ zIndex: 2000 }}
            >
                <Box
                    sx={{ width: '900px', padding: 2 }}
                    role="presentation"
                    onKeyDown={() => handleClose()}
                >
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="h3">{open.captador ? "Agente Captador" : "Agente Vendedor"}</Typography>
                        <IconButton onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <FiltroTrabajadoresDrawer
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        setFiltros={setFiltros}
                        fetchData={fetchData}
                        filtros={filtros}
                    />
                    <DataTable
                        loading={loading}
                        data={data}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        headers={headers}
                        extraHeader={[]}
                        setCustomFieldValue={setCustomFieldValue}
                        values={values}
                        setOpen={setOpen}
                        open={open}
                        setOpenDrawer={setOpen}
                        filtros={filtros}
                        drawer={open}
                        fetcher={fetchData}
                    />
                </Box>
            </Drawer>
        </>
    );
}

export default HojaDrawerTrabajadores;
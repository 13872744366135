import React, { useEffect, useState } from "react";
import {
    categoryCreate,
    categoryDetails,
    categoryUpdate,
    languageCreate,
    languageDetails,
    languageUpdate,
    listIdiomas,
    listLanguages,
} from "../../services";

import { useParams, useNavigate } from "react-router-dom";
import ButtonPermission from "../../utilities/ButtonPermission";
import * as Yup from 'yup';
import { useFormValidation } from "../../utilities/formValidator";
import { NoteAdd } from '@mui/icons-material';
import {
    Button, TextField, Box, Accordion, AccordionDetails, AccordionSummary
} from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { GetLanguageFlags, SnackbarUtilities } from "../../utilities";


function IdiomasForm() {
    const { id: idioma_id } = useParams();

    const fetchLanguages = async () => {
        try {
            const response = await languageDetails(idioma_id);            
            setValues({
                name: response.data.data.idiomas,
                iso_code: response.data.data.iso_code,
            }); 
            setEditMode(true);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (idioma_id) fetchLanguages();
    }, []);

    const [editMode, setEditMode] = useState(false);
    const navigate = useNavigate();

    const initialValues = {
        name: '',
        iso_code: '',
    };
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Campo obligatorio'),
        iso_code: Yup.string().required('Campo obligatorio')
    });
    const { values, errors, touched, handleChange, handleReset, handleBlur, handleSubmit, setCustomFieldValue, setValues } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        nullConverter: true,
        onSubmit: async (values, modifiedFields) => {
            if (values) {
                try {
                    // Llamar a la función para crear el artículo usando FormData
                    const response = editMode ? await languageUpdate(idioma_id, values) : await languageCreate(values);
                    // console.log(response);

                    if (response.status === 201) {
                        SnackbarUtilities.success("Idioma creado correctamente!!");
                        setTimeout(() => {
                            navigate(`/Idiomas`);

                        }, 2000);
                    }
                    else if (idioma_id && response.status === 200) {
                        SnackbarUtilities.success("Idioma Editado correctamente!!");
                        setTimeout(() => {
                            navigate(`/Idiomas`);

                        }, 2000);
                    }
                    else {
                        SnackbarUtilities.error("Ha habido un error, intente más tarde");
                    }
                } catch (error) {
                    console.error("Error al crear la Idioma:", error);
                    SnackbarUtilities.error("Ha habido un error, intente más tarde");
                }

            } else {
                SnackbarUtilities.error("Error en algun validador del campo");
            }
        },
    });

    return (
        <div className="container">

            <div className="top-line">
                <div style={{ textAlign: "start" }}>
                    <h3>{editMode ? 'Editar Idioma' : 'Crear Idioma'}</h3>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", flex: 0.5, justifyContent: "end" }}>
                    <ButtonPermission variant="contained" type="submit" endIcon={<NoteAdd />}
                        action={editMode ? "edit" : "create"}
                        resource="Idiomas"
                        sx={{
                            color: '#c4af6c',
                            backgroundColor: '#33383b',
                            height: 40,
                            marginBottom: 1,
                            borderColor: 'transparent',
                            '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                        }}>
                        Guardar
                    </ButtonPermission>
                </div>
                {
                    (process.env.NODE_ENV == "development" && Object.keys(errors).length > 0) &&
                    <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                        {JSON.stringify(errors)}
                    </div>
                }

                <div className="middleline">
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: { sm: '1fr 1fr' },
                                gap: 2,
                            }}
                        >
                            <div >
                                <TextField
                                    fullWidth
                                    label="Nombre del Idioma"
                                    name="name"
                                    margin="normal"
                                    required
                                    size="small"
                                    sx={inputStyles}
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                            </div>
                            <div >
                                <TextField
                                    fullWidth
                                    label="Código ISO"
                                    name="iso_code"
                                    margin="normal"
                                    required
                                    size="small"
                                    sx={inputStyles}
                                    value={values.iso_code}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.iso_code && Boolean(errors.iso_code)}
                                    helperText={touched.iso_code && errors.iso_code}
                                />
                            </div>
                        </Box>
                    </div>
                </div>
            </form>

        </div>
    );
}
const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 0,
    marginTop: 0
};

export default IdiomasForm;

import { useEffect, useRef } from "react";

const useInactivity = (onTimeout, timeout = 15 * 1000) => {
    const timeoutRef = useRef(null);

    const updateLastActivity = () => {
        const now = Date.now();
        localStorage.setItem("lastActivity", now.toString());
    };

    const checkGlobalInactivity = () => {
        const lastActivity = parseInt(localStorage.getItem("lastActivity") || "0", 10);
        const now = Date.now();

        if (now - lastActivity > timeout) {
            onTimeout(); // Ejecuta el logout si el tiempo de inactividad supera el límite
        }
    };

    useEffect(() => {
        // Actualizar el timestamp en `localStorage` al detectar actividad del usuario
        const activityHandler = () => updateLastActivity();

        // Agregar listeners de actividad
        window.addEventListener("mousemove", activityHandler);
        window.addEventListener("keydown", activityHandler);
        window.addEventListener("click", activityHandler);
        window.addEventListener("scroll", activityHandler);

        // Establecer un intervalo para verificar inactividad global
        timeoutRef.current = setInterval(checkGlobalInactivity, 1000);

        // Inicializar el timestamp en `localStorage`
        updateLastActivity();

        return () => {
            // Limpiar listeners y el intervalo al desmontar
            window.removeEventListener("mousemove", activityHandler);
            window.removeEventListener("keydown", activityHandler);
            window.removeEventListener("click", activityHandler);
            window.removeEventListener("scroll", activityHandler);
            clearInterval(timeoutRef.current);
        };
    }, [timeout, onTimeout]);
};

export default useInactivity;

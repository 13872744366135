import React, { useEffect, useState, useMemo } from "react";
import * as Yup from 'yup';
import { useFormValidation } from "../../utilities/formValidator";
import { SnackbarUtilities } from "../../utilities";
import { ContactPhone, Add, NoteAdd, Remove } from '@mui/icons-material';
import {
  TextField, Box, MenuItem, InputAdornment,
  Typography, Divider, Radio,
  RadioGroup, FormControlLabel, FormControl, Checkbox, FormGroup, InputLabel, Select, OutlinedInput, Chip
} from "@mui/material";
import ButtonPermission from "../../utilities/ButtonPermission";
import Flag from 'react-world-flags'; // Para mostrar las banderas
// import { allCountries } from 'country-region-data'; // Importa todos los países
import { createCliente, createContacto, getCliente, getContacto, listSucursales, listTrabajadores, updateCliente, updateContacto, updateTrabajador } from "../../services";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import provinces from "@doncicuto/es-provinces";
import municipalitiesWithProvinces from "../../utilities/municipalitiesWithProvinces.json"; // Ruta al JSON
import { CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { listPerfiles } from "../../services/perfiles.service";
import * as MuiIcons from '@mui/icons-material';
import ListItemIcon from '@mui/material/ListItemIcon';
import { allCountries } from "country-telephone-data";
import Flags from "react-world-flags";
import { contactosRolPermisos } from "../../utilities/permissionHelper";
import { useUser } from "../../hooks";

const countryCodes = allCountries.map((country) => ({
  code: `+${country.dialCode}`, // Código de teléfono
  country: country.name,        // Nombre del país
  flag: country.iso2.toUpperCase(), // Código de bandera ISO 3166-1
})).sort((a, b) => {
  // Priorizar España colocando su código primero
  if (a.flag === "ES") return -1; // Coloca España primero usando ISO
  if (b.flag === "ES") return 1;
  return 0; // Mantén el orden para los demás
});

export default function ContactosForm() {

  const [countryCode, setCountryCode] = useState(countryCodes[0]?.code || "+34");
  const [countryCode2, setCountryCode2] = useState(countryCodes[0]?.code || "+34");
  const [selectedCountry, setSelectedCountry] = useState(countryCodes[0]);
  const [selectedCountry2, setSelectedCountry2] = useState(countryCodes[0]);

  const handleCountryCodeChange = (event) => {
    const selected = countryCodes.find((country) => country.code === event.target.value);
    setCountryCode(selected.code);
    setSelectedCountry(selected);
  };
  const handleCountryCodeChange2 = (event) => {
    const selected = countryCodes.find((country) => country.code === event.target.value);
    setCountryCode2(selected.code);
    setSelectedCountry2(selected);
  };

  // console.log(allCountries);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStyles(name, personName, theme) {
    // console.log(name, personName);

    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const theme = useTheme();
  const { id: admin_id } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [addInputs, setAddInputs] = useState({
    telefono: false,
    email: false
  })
  const [sucursalMap, setSucursalMap] = useState(null);
  const [provinceSelected, setProvinceSelected] = useState(null);
  const [municipios, setMunicipios] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [trabajadoresList, setTrabajadoresList] = useState(null);
  const [perfilesList, setPerfilesList] = useState(null);
  const [countries, setCountries] = useState([]);
  const [personName, setPersonName] = React.useState([]);
  const { user, setUser } = useUser();
  const filtros = contactosRolPermisos(user, ["Clientes Sucursal", "Colaborador Sucursal", "AdminFincas Sucursal"]);

  const initialValues = {
    id_reg: null,
    tipo: '',//reg generales
    razon_social: '',
    nombre_comercial: '',
    nombre: '',
    apellidos: '',
    email1: '',
    email2: '',
    telefono1: '',
    telefono2: '',
    direccion: '',
    municipio: '',
    provincia: '',
    cp: '',//
    id_sucursal: admin_id ? '' : filtros.filtros.sucursal_id,//Contacto
    trabajadores: [],
    tipo_documento: '',
    numero_identificacion: '',
    nacionalidad: 'ES',
    t_aaff: '',
    email_homes: '',
    n_cuenta: '',
    inmobiliaria: false,
    estado_civil: '',
    idConyuge: '',
    nomconyuge: '',
    tipo_doc_conyuge: '',
    rep_legal: '',
    tel_rep_legal: '',
    email_rep_legal: '',
    observaciones: '',
    status: true,
    perfil: [],
  };

  const validationSchema = Yup.object().shape({
    id_reg: Yup.number().nullable().optional(),
    tipo: Yup.string().required('Campo obligatorio'), // Cambiado a number
    razon_social: Yup.string().when('tipo', {
      is: 'EMPRESA', // alternatively: (val) => val == true
      then: (schema) => schema.required('Campo obligatorio'),
      otherwise: (schema) => schema.notRequired(),
    }),
    nombre_comercial: Yup.string().nullable(),
    nombre: Yup.string().when('tipo', {
      is: 'PERSONA_FISICA', // alternatively: (val) => val == true
      then: (schema) => schema.required('Campo obligatorio'),
      otherwise: (schema) => schema.notRequired(),
    }),
    apellidos: Yup.string().when('tipo', {
      is: 'PERSONA_FISICA', // alternatively: (val) => val == true
      then: (schema) => schema.required('Campo obligatorio'),
      otherwise: (schema) => schema.notRequired(),
    }),
    email1: Yup.string().email('Formato de correo no válido').nullable().notRequired(),
    email2: Yup.string().email('Formato de correo no válido').nullable().notRequired(),
    telefono1: Yup.string().matches(/^[0-9+\-().\s]*$/, 'Formato de teléfono no válido', { excludeEmptyString: true }).nullable(),
    telefono2: Yup.string().matches(/^[0-9+\-().\s]*$/, 'Formato de teléfono no válido', { excludeEmptyString: true }).nullable(),
    direccion: Yup.string().nullable(),
    municipio: Yup.string().nullable(),
    provincia: Yup.string().nullable(),
    cp: Yup.string().nullable(),//
    id_sucursal: Yup.string().nullable().required('Campo obligatorio'),
    trabajadores: Yup.array().nullable(),
    tipo_documento: Yup.string().nullable().notRequired(),
    numero_identificacion: Yup.string().matches(/^(?:(?:[A-Z]{1}\d{7}[A-Z]{1})|(?:\d{8}[A-Z]{1})|(?:[A-Z]{2}\d{6})|(?:[ABCDEFGHJNPQRSUVW]\d{7}[A-J0-9]))$/, 'Formato Incorrecto', { excludeEmptyString: true }).nullable().notRequired().transform((value) => value === '' ? null : value),
    nacionalidad: Yup.string().nullable().notRequired(),
    email_homes: Yup.string().email('Formato de correo no válido').when('t_aaff', {
      is: true, // alternatively: (val) => val == true
      then: (schema) => schema.matches(/@laehomes\.net$/, 'Debe de ser un Email Corporativo de Laehomes.net', { excludeEmptyString: true }),
      otherwise: (schema) => schema.notRequired(),
    }).nullable(),
    n_cuenta: Yup.string().transform(value => (value == '' ? null : value)).nullable().matches(/^(\d{20}|[A-Z]{2}\d{2}[A-Z0-9]{1,30})$/, 'Formato Incorrecto', { excludeEmptyString: true }),
    inmobiliaria: Yup.boolean(),
    estado_civil: Yup.string().nullable(),
    idConyuge: Yup.string().transform(value => (value == '' ? null : value)).nullable().matches(/^(?:(?:[A-Z]{1}\d{7}[A-Z]{1})|(?:\d{8}[A-Z]{1})|(?:[A-Z]{2}\d{6}))$/, 'Formato Incorrecto', { excludeEmptyString: true }),
    nomconyuge: Yup.string().nullable(),
    tipo_doc_conyuge: Yup.string().nullable(),
    rep_legal: Yup.string().nullable(),
    tel_rep_legal: Yup.string().nullable(),
    email_rep_legal: Yup.string().email('Formato de correo no válido').nullable(),
    observaciones: Yup.string().nullable(),
    status: Yup.boolean().nullable().notRequired(),
    perfil: Yup.array().min(1, 'Debe seleccionar al menos un perfil').required('Campo obligatorio'),
    t_aaff: Yup.string().when('perfil', {
      is: (perfil) => perfil.includes(1), // Cambia '3' por el valor específico que haga 't_aaff' requerido
      then: (schema) => schema.required('Campo obligatorio'),
      otherwise: (schema) => schema.notRequired(),
    }),

  });

  // Usa la función de validación personalizada
  const { values, errors, touched, handleChange, handleReset, handleBlur, handleSubmit, setCustomFieldValue, setValues } = useFormValidation({
    initialValues: initialValues,
    validationSchema: validationSchema,
    nullConverter: true,
    onSubmit: async (values, modifiedFields) => {
      if (values) {
        // console.log(values);
        // Extraer solo las propiedades restantes con desestructuración
        const { tipo, razon_social, nombre_comercial, nombre, apellidos, email1, email2, telefono1, telefono2, direccion, municipio, provincia, cp, ...resto } = values;

        const transformedObject = {
          ...resto, // Solo las propiedades que no se transfieren a datos_generales
          email_homes: (values.t_aaff == "INTEGRAL") ? values.email_homes : null,
          reg_generales: {
            tipo,
            razon_social,
            nombre_comercial,
            nombre,
            apellidos,
            email1: email1?.toLowerCase(),
            email2: email2?.toLowerCase(),
            telefono1: values.telefono1 ? countryCode + " " + values.telefono1 : null,
            telefono2: values.telefono2 ? countryCode2 + " " + values.telefono2 : null,
            direccion,
            municipio,
            provincia,
            cp,
          }
        };
        
        const response = editMode ? await updateContacto(admin_id, { ...transformedObject, id_reg: values.id_reg }) : await createContacto(transformedObject);
        
        if (response == 201 || response == 200) {
          if (admin_id) {
            SnackbarUtilities.success("Contacto Editado correctamente!!");
          } else {
            SnackbarUtilities.success("Contacto creado correctamente!!");
          }
          setTimeout(() => {
            navigate(`/contactos`);

          }, 2000);
        } else if (response.response.status == 409) {
          SnackbarUtilities.error("Ese contacto ya existe con ese número de identificación");
        } else if (response.response?.data?.message) {
          SnackbarUtilities.error(response.response?.data?.message);
        } else {
          SnackbarUtilities.error("error");
        }

      } else {
        SnackbarUtilities.error("Error en algun validador del campo");
      }
    },
  });
  const fetchAAFF = async () => {
    if (admin_id) {
      // console.log(admin_id);

      const response = await getContacto(admin_id);
      // console.log(response.data);

      if (response.data) {
        // console.log("entroooo");
        const contacto = response.data;
        // console.log(contacto);
        const perfiles = contacto.contacto_perfiles
          ? contacto.contacto_perfiles.map(dep => dep.id)
          : [];
        const trabajadores = contacto.contacto_trabajadores
          ? contacto.contacto_trabajadores.map(tra => tra.id)
          : [];
        // console.log(perfiles);
        // console.log(trabajadores);
        contacto.reg_generales?.email2 && setAddInputs((prevState) => ({
          ...prevState,
          email: true
        }));
        contacto.reg_generales?.telefono2 && setAddInputs((prevState) => ({
          ...prevState,
          telefono: true
        }));
        setValues({
          id_reg: contacto.id_reg ?? null,  //DATOS Contacto
          perfil: perfiles ?? '',  //DATOS Contacto
          tipo: contacto.reg_generales?.tipo ?? '',
          nombre: contacto.reg_generales?.nombre ?? '',
          apellidos: contacto.reg_generales?.apellidos ?? '',
          estado_civil: contacto.estado_civil ?? '',
          nacionalidad: contacto.nacionalidad ?? '',
          tipo_documento: contacto?.tipo_documento,
          numero_identificacion: contacto.numero_identificacion ?? '',
          n_cuenta: contacto.n_cuenta ?? '',
          email1: contacto.reg_generales?.email1,
          email2: contacto.reg_generales?.email2,
          direccion: contacto.reg_generales?.direccion,
          municipio: contacto.reg_generales?.municipio,
          provincia: contacto.reg_generales?.provincia,
          cp: contacto.reg_generales?.cp,
          id_sucursal: contacto?.id_sucursal,
          trabajadores: trabajadores,
          observaciones: contacto.observaciones ?? '',
          idConyuge: contacto.idConyuge ?? '',
          nomconyuge: contacto.nomconyuge ?? '',
          tipo_doc_conyuge: contacto.tipo_doc_conyuge ?? '',
          rep_legal: contacto.rep_legal ?? '',
          tel_rep_legal: contacto.tel_rep_legal ?? '',
          email_rep_legal: contacto.email_rep_legal ?? '',
          inmobiliaria: (contacto.inmobiliaria == 1) ? true : false,
          razon_social: contacto.reg_generales?.razon_social ?? '',
          nombre_comercial: contacto.reg_generales?.nombre_comercial ?? '',
          t_aaff: contacto.t_aaff ?? '',
          email_homes: contacto.email_homes ?? '',

        });
        if (contacto.reg_generales?.telefono1) {
          const numberAndCode = contacto.reg_generales?.telefono1.match(/^(\+\d+)\s(.+)$/)?.slice(1, 3) ?? ['+34', contacto.reg_generales?.telefono1];
          setCustomFieldValue('telefono1', numberAndCode[1]);
          setCountryCode(numberAndCode[0]);
          setSelectedCountry(countryCodes.find((country) => country.code == numberAndCode[0]));
        } else {
          setCustomFieldValue('telefono1', '');
        }
        if (contacto.reg_generales?.telefono2) {
          const numberAndCode = contacto.reg_generales?.telefono2.match(/^(\+\d+)\s(.+)$/)?.slice(1, 3) ?? ['+34', contacto.reg_generales?.telefono2];
          setCustomFieldValue('telefono2', numberAndCode[1]);
          setCountryCode2(numberAndCode[0]);
          setSelectedCountry2(countryCodes.find((country) => country.code == numberAndCode[0]));
        } else {
          setCustomFieldValue('telefono2', '');
        }
        // setTrabajadoresList(trabajadores);
        const selectedProvince = provinces.filter((p) => p.name == contacto.reg_generales?.provincia);
        setProvinceSelected(selectedProvince[0]?.code);
        setEditMode(true);
      } else {
        SnackbarUtilities.error("No se pudo obtener la información del trabajador");
      }
    }
  };
  const fetchSucursales = async () => {
    const response = await listSucursales({
      offset: 1, limit: 1000, filtros: { no_off_central: 10 },
      params: {
        orderBy: 'nombre_sucursal,ASC',
      }
    });
    setSucursalMap(response.data || []);
  };

  const fetchTrabajador = async (id_sucursal) => {
    const response = await listTrabajadores({ offset: 1, limit: 1000, filtros: { sucursal_id: id_sucursal, user_rol: [21, 22] } });
    setTrabajadoresList(response.data.sort((a, b) => a.nombre.localeCompare(b.nombre)) || []);


  }
  const fetchPerfiles = async () => {
    const response = await listPerfiles({});
    setPerfilesList(response.data || []);

  }
  useEffect(() => {
    import('country-region-data').then(module => {
      setCountries(module.allCountries);
    });
    fetchPerfiles();
    fetchSucursales();
    if (admin_id) {
      fetchAAFF();
    }

  }, [])

  useEffect(() => {
    if (values.id_sucursal) {
      fetchTrabajador(values.id_sucursal);
    }
  }, [values.id_sucursal])

  const handleCustomChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (name == "nacionalidad") {
      setProvinceSelected(null);
      setCustomFieldValue('provincia', '');
      setCustomFieldValue('municipio', '');
      handleChange(event)

    }
    if (name == "provincia") {
      const selectedProvince = provinces.filter((p) => p.name == value);
      if (selectedProvince) {
        setProvinceSelected(selectedProvince[0]?.code);
        handleChange(event)
      }
    }
    if (type == "checkbox" && checked == false) {
    }
    if (name == "tipo") {
      if (value == "EMPRESA") {
        setCustomFieldValue('nombre', null);
        setCustomFieldValue('apellidos', null);
        setCustomFieldValue('estado_civil', null);
        setCustomFieldValue('idConyuge', '');
        setCustomFieldValue('nomconyuge', '');
        setCustomFieldValue('tipo_doc_conyuge', '');
        setCustomFieldValue('tipo_documento', '');
        setCustomFieldValue('numero_identificacion', '');
      } else {
        setCustomFieldValue('razon_social', null);
        setCustomFieldValue('nombre_comercial', null);
      }

    }

    if (name == "id_sucursal") {
      console.log("entro");

      setCustomFieldValue('trabajadores', []);
    }

    if (name == "telefono2") {
      // console.log("aaaa");
      const numberFormated = value.replace(countryCode2, "").trim();
      // console.log(numberFormated);
      setCustomFieldValue('telefono2', numberFormated);
    }
    handleChange(event);
    if (name == "telefono1") {
      // console.log("aaaa");
      const numberFormated = value.replace(countryCode, "").trim();
      // console.log(numberFormated);
      setCustomFieldValue('telefono1', numberFormated);
    }
    if (name == "telefono2") {
      // console.log("aaaa");
      const numberFormated = value.replace(countryCode2, "").trim();
      // console.log(numberFormated);
      setCustomFieldValue('telefono2', numberFormated);
    }
  }
  useEffect(() => {
    if (provinceSelected) {
      setLoading(true);

      // Filtrar municipios basados en la provincia seleccionada
      const filteredMunicipalities = municipalitiesWithProvinces.filter(
        (m) => m.provCode == provinceSelected
      );

      setMunicipios(filteredMunicipalities);
      setLoading(false);
    } else {
      setMunicipios([]);
    }
  }, [provinceSelected]);

  const countryOptions = useMemo(() => {
    // console.log(countries);
    // Reorganiza la lista para que España esté al inicio
    const reorderedCountries = countries.sort((a, b) => {
      if (a[1] === "ES") return -1; // España va primero
      if (b[1] === "ES") return 1;
      return 0; // Mantiene el resto en orden original
    });

    return reorderedCountries.map((country, index) => (
      <MenuItem key={index} value={country[1]}>
        <Flag code={country[1]} style={{ marginRight: 8, width: '20px', height: '15px' }} />
        {country[0]}
      </MenuItem>
    ));
  }, [countries]);

  return (
    <div className="container">
      {/* <BreadCrumb /> */}
      <div className="top-line">
        <div style={{ textAlign: "start" }}>
          <h3>{editMode ? 'Editar Contactos' : 'Crear Contactos'}</h3>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex", flex: 0.5, justifyContent: "end" }}>
          <ButtonPermission variant="contained" type="submit" endIcon={<NoteAdd />}
            action="edit"
            resource="Trabajadores"
            sx={{
              color: '#c4af6c',
              backgroundColor: '#33383b',
              height: 40,
              marginBottom: 1,
              borderColor: 'transparent',
              '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
            }}>
            Guardar
          </ButtonPermission>
        </div>
        {
          (process.env.NODE_ENV == "development" && Object.keys(errors).length > 0) &&
          <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
            {JSON.stringify(errors)}
          </div>
        }
        <div className="middleline">
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr 1fr' },
                gap: 2,
              }}
            >
              <div>
                <FormControl sx={inputStyles} size="small">
                  <InputLabel id="demo-multiple-chip-label3">Perfiles</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label3"
                    id="demo-multiple-chip-label3"
                    // disabled={(trabajadoresList ? false : true)}
                    multiple
                    name="perfil"
                    sx={inputStyles}
                    margin="normal"
                    value={values.perfil}
                    onChange={handleCustomChange}
                    input={<OutlinedInput id="select-multiple-chip-label" label="Perfiles" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={perfilesList && perfilesList.find(worker => worker.id === value)?.name} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                    onBlur={handleBlur}
                    error={touched.perfil && Boolean(errors.perfil)}
                    helperText={touched.perfil && errors.perfil}
                  >
                    {perfilesList ? perfilesList.map((worker) => {
                      const IconComponent = MuiIcons[worker.icon]; // Asumiendo que tienes un mapeo de íconos
                      return (
                        <MenuItem
                          key={worker.id}
                          value={worker.id}
                          style={getStyles(worker.id, values.perfil, theme)}
                        >
                          {/* Icono dinámico */}
                          <ListItemIcon style={{ minWidth: 35, width: 25 }}>
                            <IconComponent style={{ color: '#A69150' }} />
                          </ListItemIcon>
                          {worker.name}
                        </MenuItem>
                      );
                    }) : null}

                  </Select>
                  {touched.perfil && errors.perfil && (
                    <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                      {errors.perfil}
                    </div>
                  )}
                </FormControl>
              </div>

              <div style={{ gridColumn: 'span 1' }}>
                <TextField
                  id="demo-simple-select-label"
                  select
                  label="Tipo de Solicitante"
                  name="tipo"
                  margin="normal"
                  sx={inputStyles}
                  value={values.tipo}
                  size="small"
                  onChange={handleCustomChange}
                  onBlur={handleBlur}
                  error={touched.tipo && Boolean(errors.tipo)}
                  helperText={touched.tipo && errors.tipo}
                >
                  {[{ name: "PERSONA FÍSICA", value: "PERSONA_FISICA" }, { name: "EMPRESA", value: "EMPRESA" }].map((item, index) => (
                    <MenuItem key={item.name} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}

                </TextField>

              </div>
              {(values.tipo == 'PERSONA_FISICA') && (<>
                <div >
                  <TextField
                    fullWidth
                    label="Nombre"
                    name="nombre"
                    margin="normal"
                    size="small"
                    sx={inputStyles}
                    value={values.nombre}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.nombre && Boolean(errors.nombre)}
                    helperText={touched.nombre && errors.nombre}
                  />
                </div>
                <div >
                  <TextField
                    fullWidth
                    label="Apellidos"
                    name="apellidos"
                    margin="normal"
                    size="small"
                    sx={inputStyles}
                    value={values.apellidos}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.apellidos && Boolean(errors.apellidos)}
                    helperText={touched.apellidos && errors.apellidos}
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    label="Estado Civil"
                    name="estado_civil"
                    select
                    margin="normal"
                    size="small"
                    value={values.estado_civil}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.estado_civil && Boolean(errors.estado_civil)}
                    helperText={touched.estado_civil && errors.estado_civil}
                    sx={inputStyles}
                  >
                    {["Casado", "Soltero", "Viudo", "Divorciado"].map((state, index) => (
                      <MenuItem key={index} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </>)}
              <>
                <div>
                  <TextField
                    fullWidth
                    label="Nacionalidad"
                    name="nacionalidad"
                    select
                    margin="normal"
                    size="small"
                    value={values.nacionalidad}
                    onChange={handleCustomChange}
                    onBlur={handleBlur}
                    error={touched.nacionalidad && Boolean(errors.nacionalidad)}
                    helperText={touched.nacionalidad && errors.nacionalidad}
                    sx={inputStyles}
                  >
                    {countryOptions && <MenuItem value="">
                      <em>Ninguna</em>
                    </MenuItem>}
                    {countryOptions}

                  </TextField>
                </div>
                <div >
                  <TextField
                    id="demo-simple-select-label"
                    select
                    label="Tipo de documento"
                    name="tipo_documento"
                    margin="normal"
                    sx={inputStyles}
                    value={values.tipo_documento}
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.tipo_documento && Boolean(errors.tipo_documento)}
                    helperText={touched.tipo_documento && errors.tipo_documento}
                  >
                    <MenuItem value="">
                      <em>Ninguna</em>
                    </MenuItem>
                    {["Pasaporte", "DNI", "CIF"].map((tipo, index) => (
                      <MenuItem key={index} value={tipo}>
                        {tipo}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div >
                  <TextField
                    fullWidth
                    label="Número de Documento"
                    name="numero_identificacion"
                    margin="normal"
                    size="small"
                    sx={inputStyles}
                    value={values.numero_identificacion}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.numero_identificacion && Boolean(errors.numero_identificacion)}
                    helperText={touched.numero_identificacion && errors.numero_identificacion}
                  />
                </div>
              </>
              <div>
                <TextField
                  fullWidth
                  label="Número Cuenta Bancaria"
                  name="n_cuenta"
                  margin="normal"
                  size="small"
                  value={values.n_cuenta}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.n_cuenta && Boolean(errors.n_cuenta)}
                  helperText={touched.n_cuenta && errors.n_cuenta}
                  sx={inputStyles}
                >
                </TextField>
              </div>
              {(values.tipo == 'EMPRESA') && (<>
                <div >
                  <TextField
                    fullWidth
                    label="Razón Social"
                    name="razon_social"
                    margin="normal"
                    size="small"
                    sx={inputStyles}
                    value={values.razon_social}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.razon_social && Boolean(errors.razon_social)}
                    helperText={touched.razon_social && errors.razon_social}
                  />
                </div>
                <div >
                  <TextField
                    fullWidth
                    label="Nombre Comercial"
                    name="nombre_comercial"
                    margin="normal"
                    size="small"
                    sx={inputStyles}
                    value={values.nombre_comercial}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.nombre_comercial && Boolean(errors.nombre_comercial)}
                    helperText={touched.nombre_comercial && errors.nombre_comercial}
                  />
                </div>
              </>)}


              <div style={{ display: "flex", gridColumn: 'span 1' }} >
                <Box display="flex" alignItems="center" sx={{ gap: 0.8 }} width={"100%"}>
                  {/* Select para prefijo con banderas */}
                  <FormControl size="small" sx={{ minWidth: 70 }}>
                    <Select
                      value={countryCode}
                      onChange={handleCountryCodeChange}
                      renderValue={() => (
                        <Flags code={selectedCountry.flag} style={{ width: 25, height: 15 }} />
                      )}
                      sx={{ backgroundColor: "white" }}
                    >
                      {countryCodes.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          <Box display="flex" alignItems="center" gap={1}>
                            <Flags code={country.flag} style={{ width: 20, height: 15 }} />
                            {country.code} ({country.country})
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    id="input-with-icon-textfield"
                    name="telefono1"
                    sx={inputStyles}
                    size="small"
                    label="Teléfono"
                    // value={values.telefono1}
                    value={`${countryCode} ${values.telefono1}`}
                    InputProps={{
                      // startAdornment: (
                      //   <InputAdornment position="start">
                      //     <ContactPhone />
                      //   </InputAdornment>
                      // ),
                      endAdornment: (
                        <InputAdornment position="end" >

                          {(addInputs.telefono == false) ? <Add style={{ cursor: "pointer" }} onClick={() => {
                            setAddInputs((prevState) => ({
                              ...prevState,
                              telefono: !prevState.telefono // Aumentar el valor de 'count'
                            }));
                          }} /> : <Remove style={{ cursor: "pointer" }} onClick={() => {
                            setAddInputs((prevState) => ({
                              ...prevState,
                              telefono: !prevState.telefono // Aumentar el valor de 'count'
                            }));
                            setCustomFieldValue('telefono2', '');
                            // setCountryCode("+34");
                            // console.log(countryCodes.find((country) => country.code == "+34"));

                            // setSelectedCountry(countryCodes.find((country) => country.code == '+34'));
                          }} />}
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleCustomChange}
                    onBlur={handleBlur}
                    error={touched.telefono1 && Boolean(errors.telefono1)}
                    helperText={touched.telefono1 && errors.telefono1}
                  />
                </Box>
              </div>
              {addInputs.telefono && (
                <div >
                  <Box display="flex" alignItems="center" sx={{ gap: 0.8 }} width={"100%"}>
                    {/* Select para prefijo con banderas */}
                    <FormControl size="small" sx={{ minWidth: 70 }}>
                      <Select
                        value={countryCode2}
                        onChange={handleCountryCodeChange2}
                        renderValue={() => (
                          <Flags code={selectedCountry2.flag} style={{ width: 25, height: 15 }} />
                        )}
                        sx={{ backgroundColor: "white" }}
                      >
                        {countryCodes.map((country) => (
                          <MenuItem key={country.code} value={country.code}>
                            <Box display="flex" alignItems="center" gap={1}>
                              <Flags code={country.flag} style={{ width: 20, height: 15 }} />
                              {country.code} ({country.country})
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      id="input-with-icon-textfield"
                      name="telefono2"
                      sx={inputStyles}
                      size="small"
                      label="Segundo Teléfono"
                      // value={values.telefono2}
                      value={`${countryCode2} ${values.telefono2}`}
                      InputProps={{
                        // startAdornment: (
                        //   <InputAdornment position="start">
                        //     <ContactPhone />
                        //   </InputAdornment>
                        // )
                      }}
                      onChange={handleCustomChange}
                      onBlur={handleBlur}
                      error={touched.telefono2 && Boolean(errors.telefono2)}
                      helperText={touched.telefono2 && errors.telefono2}
                    />
                  </Box>
                </div>
              )}
              <div >
                <TextField
                  id="input-with-icon-textfield"
                  name="email1"
                  sx={inputStyles}
                  size="small"
                  label="Email"
                  value={values.email1}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" >
                        {(addInputs.email == false) ? <Add style={{ cursor: "pointer" }} onClick={() => {
                          setAddInputs((prevState) => ({
                            ...prevState,
                            email: !prevState.email // Aumentar el valor de 'count'
                          }));
                        }} /> : <Remove style={{ cursor: "pointer" }} onClick={() => {
                          setAddInputs((prevState) => ({
                            ...prevState,
                            email: !prevState.email // Aumentar el valor de 'count'
                          }));
                        }} />}

                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email1 && Boolean(errors.email1)}
                  helperText={touched.email1 && errors.email1}
                />
              </div>
              {
                addInputs.email && (
                  <div >
                    <TextField
                      id="input-with-icon-textfield"
                      name="email2"
                      sx={inputStyles}
                      size="small"
                      label="Segundo Email"
                      value={values.email2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email2 && Boolean(errors.email2)}
                      helperText={touched.email2 && errors.email2}
                    />
                  </div>
                )
              }
              {/* <Typography variant="h6" gutterBottom style={{ gridColumn: 'span 2' }} align="center" sx={{ border: "1px solid black" }} my={2}>
                Datos Generales
              </Typography> */}

              <div >
                <TextField
                  fullWidth
                  label="Dirección"
                  name="direccion"
                  margin="normal"
                  size="small"
                  sx={inputStyles}
                  value={values.direccion}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.direccion && Boolean(errors.direccion)}
                  helperText={touched.direccion && errors.direccion}
                />
              </div>
              <div >
                <TextField
                  id="demo-simple-select-label"
                  select
                  label="Provincia"
                  name="provincia"
                  disabled={(values.nacionalidad == "ES") ? false : true}
                  margin="normal"
                  sx={inputStyles}
                  value={values.provincia}
                  size="small"
                  onChange={handleCustomChange}
                  onBlur={handleBlur}
                  error={touched.provincia && Boolean(errors.provincia)}
                  helperText={touched.provincia && errors.provincia}
                >
                  <MenuItem value="">
                    <em>Ninguna</em>
                  </MenuItem>
                  {provinces.sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => (
                    <MenuItem key={index} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div >
                <TextField
                  id="demo-simple-select-label"
                  select
                  disabled={(admin_id || provinceSelected) ? false : true}
                  label="Municipio"
                  name="municipio"
                  margin="normal"
                  sx={inputStyles}
                  value={values.municipio}
                  size="small"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.municipio && Boolean(errors.municipio)}
                  helperText={touched.municipio && errors.municipio}
                >
                  {loading ? (
                    <MenuItem disabled>
                      <CircularProgress size={24} />
                    </MenuItem>
                  ) : (
                    municipios.map((item, index) => (
                      <MenuItem key={index} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))
                  )}
                </TextField>
              </div>
              <div >
                <TextField
                  fullWidth
                  label="Código Postal"
                  name="cp"
                  margin="normal"
                  size="small"
                  sx={inputStyles}
                  value={values.cp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.cp && Boolean(errors.cp)}
                  helperText={touched.cp && errors.cp}
                />
              </div>
              <div >
                <TextField
                  id="demo-simple-select-label"
                  select
                  label="Oficina"
                  name="id_sucursal"
                  disabled={!admin_id ? contactosRolPermisos(user, ["Clientes Sucursal", "Colaborador Sucursal", "AdminFincas Sucursal"]).adicionales.disable : false}
                  margin="normal"
                  sx={inputStyles}
                  value={values.id_sucursal}
                  size="small"
                  onChange={handleCustomChange}
                  onBlur={handleBlur}
                  error={touched.id_sucursal && Boolean(errors.id_sucursal)}
                  helperText={touched.id_sucursal && errors.id_sucursal}
                >
                  {sucursalMap &&
                    <MenuItem value="">
                      <em>Ninguna</em>
                    </MenuItem>
                  }
                  {sucursalMap ? sucursalMap.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.nombre_sucursal ?? "LAE HOMES - " + option.municipio}
                    </MenuItem>
                  )) : <MenuItem disabled>No hay oficinas disponibles</MenuItem>}

                </TextField>

              </div>
              <div>
                <FormControl sx={inputStyles} size="small">
                  <InputLabel id="demo-multiple-chip-label3">Trabajadores</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label3"
                    id="demo-multiple-chip-label3"
                    disabled={(trabajadoresList ? false : true)}
                    multiple
                    name="trabajadores"
                    sx={inputStyles}
                    margin="normal"
                    value={values.trabajadores}
                    onChange={handleCustomChange}
                    input={<OutlinedInput id="select-multiple-chip-label" label="Departamentos" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={trabajadoresList?.find(worker => worker.id == value)?.nombre || 'Trabajador no encontrado'}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                    onBlur={handleBlur}
                    error={touched.trabajadores && Boolean(errors.trabajadores)}
                    helperText={touched.trabajadores && errors.trabajadores}
                  >
                    {trabajadoresList ? trabajadoresList.map((worker) => (
                      <MenuItem
                        key={worker.id}
                        value={worker.id}
                        style={getStyles(worker.id, values.trabajadores, theme)}
                      >
                        {worker.nombre + " " + worker.apellidos + " - " + worker.cargo}
                      </MenuItem>
                    )) : null}
                  </Select>
                  {touched.trabajadores && errors.trabajadores && (
                    <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                      {errors.trabajadores}
                    </div>
                  )}
                </FormControl>
              </div>

              {
                values.perfil?.includes(3) && <>
                  <div style={{ display: 'flex', justifyContent: "center" }}>
                    <FormGroup row={true}>
                      <FormControlLabel disabled={!values.perfil?.includes(3)} control={<Checkbox
                        checked={values.inmobiliaria}
                        size="small"
                        name="inmobiliaria"
                        // color="default"
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        onChange={handleCustomChange}
                        onBlur={handleBlur}
                        error={touched.inmobiliaria && Boolean(errors.inmobiliaria)}
                        helperText={touched.inmobiliaria && errors.inmobiliaria}
                      />} label="Inmobiliaria" />
                    </FormGroup>
                  </div>
                </>
              }
              {
                values.perfil?.includes(1) && <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <FormControl >
                    {/* <FormLabel id="demo-t_aaff">Colaborador</FormLabel> */}
                    <RadioGroup
                      aria-labelledby="demo-t_aaff"
                      name="t_aaff"
                      value={values.t_aaff}
                      onChange={handleChange}
                      row
                    >
                      <FormControlLabel value="INTEGRAL" control={<Radio />} label="Colaborador Integral" />
                      <FormControlLabel value="PRESCRIPTOR" control={<Radio />} label="Prescriptor" />
                    </RadioGroup>
                    {touched.t_aaff && errors.t_aaff && (
                      <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                        {errors.t_aaff}
                      </div>
                    )}
                  </FormControl>
                </div>
              }
              {(values.t_aaff == "INTEGRAL") &&
                <div>
                  <TextField
                    fullWidth
                    label="Email Prescriptor"
                    name="email_homes"
                    margin="normal"
                    size="small"
                    sx={inputStyles}
                    value={values.email_homes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email_homes && Boolean(errors.email_homes)}
                    helperText={touched.email_homes && errors.email_homes}
                  />
                </div>}
              <div style={{ gridColumn: 'span 3' }}>
                <TextField
                  fullWidth
                  label="Observaciones"
                  name="observaciones"
                  margin="normal"
                  size="small"
                  multiline
                  rows={4}
                  maxRows={6}
                  sx={inputStyles}
                  value={values.observaciones}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.observaciones && Boolean(errors.observaciones)}
                  helperText={touched.observaciones && errors.observaciones}
                />
              </div>

            </Box>
            <Divider sx={{ borderColor: '#c4af6c', borderWidth: '1.5px', marginBlock: 2 }} />
            <Typography variant="h4" sx={{ textAlign: 'left', color: '#33383b', marginBottom: '16px', fontSize: 30 }}>
              Representante Legal
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr 1fr' },
                gap: 2,
              }}
            >
              <div >
                <TextField
                  fullWidth
                  label="Representante Legal"
                  name="rep_legal"
                  margin="normal"
                  size="small"
                  sx={inputStyles}
                  value={values.rep_legal}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.rep_legal && Boolean(errors.rep_legal)}
                  helperText={touched.rep_legal && errors.rep_legal}
                />
              </div>
              <div >
                <TextField
                  fullWidth
                  label="Correo"
                  name="email_rep_legal"
                  margin="normal"
                  size="small"
                  sx={inputStyles}
                  value={values.email_rep_legal}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email_rep_legal && Boolean(errors.email_rep_legal)}
                  helperText={touched.email_rep_legal && errors.email_rep_legal}
                />
              </div>
              <div >
                <TextField
                  fullWidth
                  label="Teléfono"
                  name="tel_rep_legal"
                  margin="normal"
                  size="small"
                  sx={inputStyles}
                  value={values.tel_rep_legal}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.tel_rep_legal && Boolean(errors.tel_rep_legal)}
                  helperText={touched.tel_rep_legal && errors.tel_rep_legal}
                />
              </div>
            </Box>
            <Divider sx={{ borderColor: '#c4af6c', borderWidth: '1.5px', marginBlock: 2 }} />
            <Typography variant="h4" sx={{ textAlign: 'left', color: '#33383b', marginBottom: '16px', fontSize: 30 }}>
              Conyuge
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr 1fr' },
                gap: 2,
              }}
            >
              <div >
                <TextField
                  fullWidth
                  label="Conyuge"
                  name="nomconyuge"
                  margin="normal"
                  size="small"
                  sx={inputStyles}
                  value={values.nomconyuge}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nomconyuge && Boolean(errors.nomconyuge)}
                  helperText={touched.nomconyuge && errors.nomconyuge}
                />
              </div>
              <div >
                <TextField
                  id="demo-simple-select-label"
                  select
                  label="Tipo Documentación"
                  name="tipo_doc_conyuge"
                  margin="normal"
                  sx={inputStyles}
                  value={values.tipo_doc_conyuge}
                  size="small"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.tipo_doc_conyuge && Boolean(errors.tipo_doc_conyuge)}
                  helperText={touched.tipo_doc_conyuge && errors.tipo_doc_conyuge}
                >

                  {["Pasaporte", "DNI"].map((tipo, index) => (
                    <MenuItem key={index} value={tipo}>
                      {tipo}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div >
                <TextField
                  fullWidth
                  label="Número Documentación"
                  name="idConyuge"
                  margin="normal"
                  size="small"
                  sx={inputStyles}
                  value={values.idConyuge}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.idConyuge && Boolean(errors.idConyuge)}
                  helperText={touched.idConyuge && errors.idConyuge}
                />
              </div>
            </Box>
          </div>
        </div>
      </form>
    </div>
  )
}
const inputStyles = {
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#c4af6c',
    },
  },
  '& .MuiInputAdornment-root': {
    color: '#333', // Color del adorno
  },
  width: "100%",
  marginBottom: 0,
  marginTop: 0
};
const styledButton = {
  backgroundColor: '#33383b',
  color: '#c4af6c',
  '&:hover': {
    backgroundColor: '#c4af6c',
    color: '#33383b',
  },
};

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Typography,
    Chip,
} from '@mui/material';
import { DataTable, FiltrosHojasFormalizacion } from "../../components";
import { AiFillFileAdd } from "react-icons/ai";
import { useHojasForm } from "../../hooks";
import { deleteHojaForm } from "../../services";
import Swal from "sweetalert2";
import { useUser } from "../../hooks/UserContextLogin";
import * as Yup from 'yup';
import { listSucursales } from "../../services/sucursales.service.js";
import { SnackbarUtilities } from "../../utilities";
import { useFormValidation } from "../../utilities/formValidator";
import ButtonPermission from "../../utilities/ButtonPermission.jsx";
import { accionesHojasForm } from "../../utilities/permissionHelper.js";

const HojasFormalizacion = () => {
    const { user, setUser } = useUser();
    const [sucursalMap, setSucursalMap] = useState(null);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [filtros, setFiltros] = useState({ id_sucursal: user?.rol?.permisos?.customViews['HojaDeFormalizacion']?.includes('Create HojasF') ? user?.usuarioDatos?.sucursal_id : null });
    const { loading, limite, data, fetchData } = useHojasForm();
    
    const initialValues = {
        fechaDesde: '',
        fechaHasta: '',
        id_sucursal: user?.rol?.permisos?.customViews['HojaDeFormalizacion']?.includes('Create HojasF') ? user?.usuarioDatos?.id_sucursal : '',
        accion: '',
        estado: ''
    };

    const validationSchema = Yup.object().shape({
        fechaDesde: Yup.string()
            .nullable(),
        fechaHasta: Yup.string()
            .nullable(),
        id_sucursal: Yup.string().nullable(),
        accion: Yup.string().nullable(),
        estado: Yup.string().nullable(),
    });

    // Usa la función de validación personalizada
    const { values, errors, touched, handleChange, handleReset, handleBlur, handleSubmit, setCustomFieldValue, setValues } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        nullConverter: true,
        onSubmit: async (values) => {
            // console.log(values);
            let { fechaDesde, fechaHasta, ...newValues } = values;
            if (fechaDesde && fechaHasta) {
                newValues.fecha = {
                    fechaDesde,
                    fechaHasta
                };
            }
            if (newValues) {
                fetchData({ offset: currentPage, limit: 1000, filtros: newValues });
            } else {
                SnackbarUtilities.error("Error en algun validador del campo");
            }
        },
    });
    const fetchSucursales = async () => {
        const response = await listSucursales({ offset: 1, limit: 1000, filtros: { no_off_central: 10 } });
        setSucursalMap(response.data.sort((a, b) => a.provincia.localeCompare(b.provincia)) || []);
    };
    const headers = [
        {
            referencia: "referencia",
            numeric: false,
            disablePadding: false,
            label: "Referencia",
            name: "referencia",
            overrideFunc: (data, row) => (
                <Typography
                    sx={{
                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem"
                    }}
                >
                    {row.referencia}
                </Typography>
            ),
        },
        {
            oficina: "oficina",
            numeric: false,
            disablePadding: false,
            label: "Oficina",
            name: "id_sucursal",
            overrideFunc: (data, row) => (
                <Typography
                    sx={{
                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem"
                    }}
                >
                    {row.sucursal?.nombre_sucursal}
                </Typography>
            ),
        },
        {
            fechaOp: "fechaOp",
            numeric: false,
            disablePadding: false,
            name: "fechaOperacion",
            label: "Fecha Operación",
            overrideFunc: (data, row) => (
                <Typography
                    sx={{
                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem"
                    }}
                >
                    {row?.fechaOperacion}
                </Typography>
            ),
        },
        {
            name: "inmueble",
            numeric: false,
            disablePadding: false,
            order: 'disable',
            label: "Ref/Tipo inmmueble",
            overrideFunc: (data, row) => (
                row?.propiedades?.map(prop => (
                    <Typography
                        sx={{
                            color: "#33383b !important",
                            "&:hover": { color: "#33383b" },
                            "&:active": { color: "#33383b" },
                            fontSize: "0.9rem",
                            fontWeight: "1rem"
                        }}
                    >
                        {prop.referencia} / {prop.tipo_ofer}
                    </Typography>
                ))

            ),
        },
        {
            name: "accion",
            numeric: false,
            disablePadding: false,
            label: "Operación",
            overrideFunc: (data, row) => (
                <Typography
                    sx={{
                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem"
                    }}
                >
                    {row.accion}
                </Typography>
            ),
        },
        {
            name: "estado",
            numeric: false,
            disablePadding: false,
            label: "Estado",
            overrideFunc: (data, row) => (
                <>
                    {
                        row.estado === 'pendiente_validacion_responsable'
                            ? <Chip variant="outlined" label="Validación Pendiente" color="warning" />
                            : row.estado === 'validado_por_responsable'
                                ? <Chip variant="outlined" label="Validado Responsable" color="success" />
                                : row.estado === 'verificado_por_administrador'
                                    ? <Chip variant="outlined" label="Validado" color="info" />
                                    : <Chip variant="outlined" label={row.estado} color="default" />
                    }

                </>
            ),
        },
        {
            name: "total",
            numeric: false,
            disablePadding: false,
            label: "Precio",
            overrideFunc: (data, row) => (
                <Typography
                    sx={{
                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem",
                        textAlign: "right"
                    }}
                >
                    {row.total.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
                </Typography>
            ),
        },
        {
            numeric: false,
            disablePadding: false,
            label: "Acciones",
            order: "disable",
            overrideFunc: (data, row) => (
                accionesHojasForm(user, row, `/HojaDeFormalizacion/editar/${row.id}`, handleDelete)
            ),
        }
    ];
    useEffect(() => {
        fetchSucursales();
    }, [])


    const handleDelete = (id) => {
        Swal.fire({
            title: "¿Está seguro de que quiere eliminar la publicación?",
            icon: "warning",
            showDenyButton: true,
            confirmButtonText: `Si`,
            confirmButtonColor: "#48cc90",
            denyButtonText: `No`,
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const res = await deleteHojaForm(id);
                if (res.status === 201) {
                    await fetchData({ offset: currentPage, limit: limite, filtros: filtros });
                    Swal.fire(
                        "¡Eliminado!",
                        "Publicacion Eliminada correctamente",
                        "success"
                    );

                }
            }
        });
    }
    return (
        <>
            <div className="container">
                {/* <BreadCrumb /> */}
                <div className="topline">
                    <div>
                        <h3>Hojas de formalización</h3>
                    </div>
                    <div>
                        <ButtonPermission onClick={() => navigate("/HojaDeFormalizacion/crear",
                            { state: { sucursal: user?.rol?.permisos?.customViews['HojaDeFormalizacion']?.includes('Create HojasF') ? user?.sucursal : null } })}
                            variant="contained" endIcon={<AiFillFileAdd />}
                            action="create"
                            resource="HojaDeFormalizacion"
                            sx={{
                                color: '#c4af6c',
                                backgroundColor: '#33383b',
                                height: 40,
                                marginBottom: 1,
                                borderColor: 'transparent',
                                '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                            }}>
                            Crear Hoja
                        </ButtonPermission>
                    </div>
                </div>
                <FiltrosHojasFormalizacion
                    handleSubmit = {handleSubmit}
                    inputStyles = {inputStyles}
                    errors = {errors}
                    values = {values}
                    handleBlur = {handleBlur}
                    handleChange = {handleChange}
                    handleReset = {handleReset}
                    fetchData = {fetchData}
                    user = {user}
                    touched = {touched}
                    sucursalMap = {sucursalMap}
                    currentPage= {currentPage}
                />
                <div className="middleline">
                    <DataTable
                        headers={headers}
                        data={data}
                        isLoading={loading}
                        chickable = {false}
                        fetcher={fetchData}
                        mensaje={"No hay Hojas disponibles"}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        filtros={filtros}
                        // orderTable={"ASC"}
                        // orderTableBy={"id_sucursal"}
                    />
                </div>
            </div>
        </>
    );
};
const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 0,
    marginTop: 0
};
export default HojasFormalizacion;
import { useEffect } from "react";
import jwtDecode from "jwt-decode";

const useTokenExpiration = (logoutCallback) => {
  // Comprueba si el token es válido
  const checkTokenExpiration = (token) => {
    try {
      const decoded = jwtDecode(token);
      const now = Date.now() / 1000; // Tiempo actual en segundos
      return decoded.exp > now; // Retorna true si el token es válido
    } catch (error) {
      return false; // Token no válido o malformado
    }
  };

  // Obtiene el tiempo de expiración del token
  const getTokenExpirationTime = (token) => {
    try {
      const decoded = jwtDecode(token);
    //   console.log(decoded.exp);
      
      return decoded.exp * 1000; // Tiempo de expiración en milisegundos
    } catch (error) {
      return null; // Si el token es inválido
    }
  };

  // Configura un temporizador para desloguear al usuario
  const handleTokenExpiration = (token) => {
    const expirationTime = getTokenExpirationTime(token);
    if (expirationTime) {
      const timeUntilExpiration = expirationTime - Date.now();
      if (timeUntilExpiration > 0) {
        setTimeout(() => {
          logoutCallback(); // Llama al callback para desloguear al usuario
        }, timeUntilExpiration);
      } else {
        logoutCallback(); // Si ya está expirado, desloguea inmediatamente
      }
    } else {
      logoutCallback(); // Si el token es inválido, también desloguea
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      if (checkTokenExpiration(token)) {
        // console.log("entro");
        
        handleTokenExpiration(token); // Configura el temporizador
      } else {
        // console.log("entro al else");
        logoutCallback(); // Si ya expiró, desloguea al usuario
      }
    } else {
        // console.log("entro al else2");
      logoutCallback(); // No hay token, desloguea al usuario
    }

    // Escucha cambios en el `localStorage` para sincronización entre pestañas
    const handleStorageChange = (event) => {
      if (event.key === "token" && (!event.newValue || !checkTokenExpiration(event.newValue))) {
        logoutCallback(); // Si el token expiró o fue eliminado en otra pestaña, desloguea
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange); // Limpia el listener al desmontar
    };
  }, [logoutCallback]);
};

export default useTokenExpiration;

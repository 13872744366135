import React from "react";
import { NoteAdd, Home, Add, Height } from "@mui/icons-material";
import {
  Button,
  TextField,
  Box,
  Typography,
  InputAdornment,
  Divider,
  FormHelperText,
} from "@mui/material";
import { Person, Delete, AccountCircle } from "@mui/icons-material";

export default function HojaFormCompradores({
  values,
  errors,
  touched,
  categories,
  destinations,
  handleChange,
  setCustomFieldValue,
  setValues,
  sidePropOpen,
  setSidePropOpen,
}) {
  const accion = values.accion === "ALQUILER" ? "Arrendatario" : "Comprador";
  const compradoresCount = values?.clientes_hojas.filter(cliente => cliente.rol === "COMPRADOR").length;
  const accionPlural = compradoresCount > 1 ? (accion === "Arrendatario" ? "Arrendatarios" : "Compradores") : accion;
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "left",
            color: "#33383b",
            marginBottom: "16px",
          }}
        >
          {accionPlural}
        </Typography>
        <Button
          variant="contained"
          sx={styledButton}
          onClick={() => {
            setSidePropOpen((prevState) => ({
              ...prevState,
              comprador: true,
              // clientes: true,
              vendedor: false,
            }));
          }}
          startIcon={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Add />
              <Person />
            </Box>
          }
        />
      </Box>
      {values?.clientes_hojas.map((item, index) => {
        return (
          item.rol == "COMPRADOR" 
          ?
            <Box>
              <Box
                sx={{
                  marginLeft: "5px",
                  marginBottom: "2px",
                  textAlign: "right",
                  marginTop: "2px",
                }}
              ></Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { sm: "1fr 1fr 1fr 1fr" },
                  gap: 1.2,
                  width: "95%",
                }}
              >
                <div style={{ gridColumn: "span 2" }}>
                  <TextField
                    id="filled-helperText"
                    size="small"
                    placeholder="Nombre y Apellidos"
                    label="Titular"
                    sx={inputStyles}
                    value={
                      values.clientes_hojas[index]?.nombre || values.clientes_hojas[index]?.apellidos
                        ? `${values.clientes_hojas[index]?.nombre ?? ""} ${values.clientes_hojas[index]?.apellidos ?? ""}`
                        : values.clientes_hojas[index]?.nombre_comercial ?? ""
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" >
                          <Person sx={{ fontSize: "2em", color: "#c4af6c" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div style={{ gridColumn: "span 1" }}>
                  <TextField
                    id="filled-helperText"
                    size="small"
                    placeholder="NIF"
                    label="NIF"
                    sx={inputStyles}
                    value={
                      values.clientes_hojas[index].numero_identificacion ?? ""
                    }
                  />
                </div>
                <div style={{ gridColumn: "span 1" }}>
                  <Button
                    sx={{ ...iconButtonStyles }}
                    onClick={() => {
                      setCustomFieldValue(
                        "clientes_hojas",
                        values.clientes_hojas.filter((_, i) => i !== index)
                      );
                    }}
                  // size="small"
                  >
                    <Delete />
                  </Button>
                </div>
              </Box>
              {touched.clientes_hojas && errors.clientes_hojas && (
                <FormHelperText sx={{ color: "#d32f2f" }}>{errors.clientes_hojas}</FormHelperText>
              )}
              {index < values?.clientes_hojas.length && (
                <Divider
                  variant="middle"
                  sx={{
                    borderColor: "#c4af6c",
                    borderWidth: "1.5px",
                    marginBottom: "20px",
                    marginTop: "10px",
                    width: "80%",
                  }}
                />
              )}
            </Box> 
          :
            <></>
        );
      })}
      {values?.clientes_hojas.length == 0 && (
        <Divider
          variant="middle"
          sx={{
            borderColor: "#c4af6c",
            borderWidth: "1.5px",
            marginBottom: "10px",
            marginTop: "40px",
            width: "80%",
          }}
        />
      )}
    </Box>
  );
}
const inputStyles = {
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#33383b !important", // Cambia el color del label cuando el TextField está seleccionado
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#c4af6c",
    },
  },
  "& .MuiInputAdornment-root": {
    color: "#333", // Color del adorno
  },
  width: "100%",
  marginBottom: 0,
  marginTop: 0,
};
const styledButton = {
  backgroundColor: "#33383b",
  color: "#c4af6c",
  "&:hover": {
    backgroundColor: "#c4af6c",
    color: "#33383b",
  },
};
const iconButtonStyles = {
  height: "100%",
  backgroundColor: "#33383b",
  color: "#c4af6c",
  "&:hover": {
    backgroundColor: "#c4af6c",
    color: "#33383b",
  },
};

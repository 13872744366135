import React from "react";
import HojaDrawerPropiedades from "./HojaDrawerPropiedades";
import HojaDrawerComprador from "./HojaDrawerComprador";
import HojaDrawerVendedor from "./HojaDrawerVendedor";
import HojaDrawerAaff from "./HojaDrawerAaff";
import HojaDrawerTrabajadores from "./HojaDrawerTrabajadores";
import HojaDrawerColaborador from "./HojaDrawerColaborador";

export default function HojaDrawerComponent({
  open,
  setOpen,
  values,
  handleChange,
  setCustomFieldValue,
}) {
  return (
    <>
      <HojaDrawerPropiedades
        open={open}
        setOpen={setOpen}
        values={values}
        handleChange={handleChange}
        setCustomFieldValue={setCustomFieldValue}
      />
      <HojaDrawerComprador
        open={open}
        setOpen={setOpen}
        values={values}
        handleChange={handleChange}
        setCustomFieldValue={setCustomFieldValue}
      />
      <HojaDrawerVendedor
        open={open}
        setOpen={setOpen}
        values={values}
        handleChange={handleChange}
        setCustomFieldValue={setCustomFieldValue}
      />
      <HojaDrawerAaff
        open={open}
        setOpen={setOpen}
        values={values}
        handleChange={handleChange}
        setCustomFieldValue={setCustomFieldValue}
      />
      <HojaDrawerTrabajadores
        open={open}
        setOpen={setOpen}
        values={values}
        handleChange={handleChange}
        setCustomFieldValue={setCustomFieldValue}
      />
      <HojaDrawerColaborador
        open={open}
        setOpen={setOpen}
        values={values}
        handleChange={handleChange}
        setCustomFieldValue={setCustomFieldValue}
      />
      {/* <DrawerClientes
        open={open}
        toSave={"comprador"}
        sucursal={formData?.sucursal || sucursalChoosed || sucursal}
        setOpen={setOpen}
        setCliente={setFormData}
        cliente={formData.comprador}
      /> */}
    </>
  );
}

import { useState, useContext, useEffect } from "react";
import { listHojasForm } from "../services/hojaForm.service";
import { formatDate, formatDateGuiones, formatDateSlashEs } from "../utilities";
function useHojasForm() {
    const [limite, setLimite] = useState(0);
    const [data, setData] = useState({
        meta: {},
        rows: [],
    });
    const [loading, setLoading] = useState(true);

    const formatData = (data) => {
        return data?.map((row) => {
            return {
                id: row.id,
                referencia: row.referencia,
                id_sucursal: row.id_sucursal,
                fechaOperacion: formatDateSlashEs(row?.fechaOperacion),
                accion: row.accion,
                tipoCom: row.tipoCom,
                id_comprador: row.id_comprador,
                id_captador: row.id_captador,
                porCaptador: row.porCaptador,
                totalCaptador: row.totalCaptador,
                id_vendedor: row.id_vendedor,
                id_agVendedor: row.id_agVendedor,
                porVendedor: row.porVendedor,
                totalVendedor: row.totalVendedor,
                total: row.total,
                comisionTotal: row.comisionTotal,
                porLae: row.porLae,
                comisionLae: row.comisionLae,
                totalComisionNeta: row.totalComisionNeta,
                opCompartida: row.opCompartida,
                haya: row.haya,
                vinculo2: row.vinculo2,
                vinculo3: row.vinculo3,
                propiedades: row.propiedades,
                observacion: row.observacion,
                sucursal: row.su_sucursal,
                estado: row.estado
            };
        });
    };
    const formatUnicData = (data) => {
        const { 
            participantes_hojas, 
            propiedades_hojas, 
            clientes_hojas, 
            trabajadores_hojas 
        } = data;
      

        data.propiedades_hojas = propiedades_hojas.map(prop => ({
            id_prop:prop.id_prop,
            direccion:prop.direccion,
            provincia:prop.provincia,
            municipio:prop.municipio,
            cp:prop.cp,
            promocion:prop.promocion,
            importe:prop.importe
        }));
        data.clientes_hojas = clientes_hojas.map(cli => ({
            id_cliente: cli?.id,
            rol:cli?.rClientesHojas?.rol,
            numero_identificacion:cli.numero_identificacion,
            nombre: cli.reg_generales.nombre,
            apellidos: cli.reg_generales.apellidos,
            razon_social: cli.reg_generales.razon_social 
                ? cli.reg_generales.razon_social 
                : cli.reg_generales.nombre_comercial,
            
        }));
        data.trabajadores_hojas = trabajadores_hojas.map(trb =>({
            id_trabajador:trb.id_trabajador,
            nombre: trb.nombre,
            apellidos: trb.apellidos,
            rol: trb.rol,
            vinculo: trb.vinculo,
            porcentaje: trb.porcentaje,
            total: trb.total,
        }));
        data.participantes_hojas = participantes_hojas.map(prt =>({
            id_contacto:prt.id,
            nombre: prt.reg_generales.nombre,
            apellidos: prt.reg_generales.apellidos,
            numero_identificacion: prt.numero_identificacion,
            razon_social: prt.reg_generales.razon_social 
                ? prt.reg_generales.razon_social 
                : prt.reg_generales.nombre_comercial,
            rol: prt.rContactosHojas.rol,
            vinculo: prt.rContactosHojas.vinculo,
            porcentaje: prt.rContactosHojas.porcentaje,
            total: prt.rContactosHojas.total,
        }));
        let new_data = {
            id_sucursal: data?.su_sucursal?.id_ext,
            sucursal_raw_id:data?.id_sucursal,
            accion: data?.accion,
            referencia: data?.referencia,
            comisionTotal: data?.comisionTotal,
            tipoCom: data?.tipoCom,
            comisionLae: data?.comisionLae,
            total: data?.total,
            captador: data?.captador,
            totalComisionNeta: data?.totalComisionNeta,
            fechaOperacion: data?.fechaOperacion,
            opCompartida: data?.opCompartida,
            porCoor: data?.porCoor,
            totalCoor: data?.totalCoor,
            observacion: data?.observacion,
            porLae: data?.porLae,
            propiedades: data?.propiedades_hojas,
            clientes_hojas: data?.clientes_hojas,
            trabajadores_hojas: data?.trabajadores_hojas,
            participantes_hojas: data?.participantes_hojas,
            estado: data?.estado
        };
        return new_data;
    };
    const fetchData = async ({ offset, limit, filtros, orderBy, order }) => {
        setLimite(limit);
        let payload;
        if (orderBy && order) {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit), orderBy: `${orderBy},${order}` },
                filtros
            };
        } else {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit) },
                filtros
            };
        }
        try {
            setLoading(true);
            let response = await listHojasForm(payload);
            // console.log(response.data.data[0].su_sucursal.nombre_sucursal);
            const { meta, data } = response.data;
            // console.log(formatData(data)[0].sucursal.nombre_sucursal);

            // console.log(data)
            // console.log(formatData(data));

            setData({
                meta,
                rows: formatData(data),
            });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    return {
        data,
        fetchData,
        loading,
        formatData,
        formatUnicData,
        limite
    };

};

export default useHojasForm;
//Funcion para buscar un parametro del array y sumar su total de todos los obj y luego sumarle o restarle el valor añadido que se pasa por parametro
export function operarPropiedad(array, operacion, valorPersonalizado, propiedad) {
  // Verificamos si el array es válido
  if (!Array.isArray(array)) {
    throw new Error("El primer parámetro debe ser un array.");
  }

  // Verificamos que la operación sea válida
  if (operacion !== "sumar" && operacion !== "restar") {
    throw new Error("La operación debe ser 'sumar' o 'restar'.");
  }

  // Verificamos que la propiedad sea una cadena de texto
  if (typeof propiedad !== "string" || propiedad.trim() === "") {
    throw new Error("La propiedad debe ser un string no vacío.");
  }
  let totalPropiedad = 0;

  array.forEach((item) => {
    let valor = item[propiedad]; 
    if (typeof valor === "string") {
      valor = valor.replace(/\.(?=\d{3}(,|$))/g, "").replace(",", ".");
      valor = parseFloat(valor);
    }
    if (typeof valor === "number" && !isNaN(valor)) {
      if(operacion === "sumar"){
        totalPropiedad += valor;
      } else if(operacion === "restar"){
        totalPropiedad -= valor;
      }
    }
  });

  return totalPropiedad;
  // let resultado;
  // if (operacion === "sumar") {
  //   resultado = totalPropiedad + valorPersonalizado;
  //   console.log("Sumar", resultado);
  // } else if (operacion === "restar") {
  //   resultado = totalPropiedad - valorPersonalizado;
  // }

  // Calculamos la suma de la propiedad dinámica
  // const totalPropiedad = array.reduce((total, item) => {
   
  //   let valor = item[propiedad];
  //   let resultado = 0;
  //   if (typeof valor === "string" || valor.includes(",")) {
  //     valor = parseFloat(valor.replace(/\./g, "").replace(",", "."));
  //   }
  //   // if (typeof valor === "number" && !isNaN(valor)) {
  //   //   resultado =  total + valor;
  //   // } 
  //   return valor;
  // }, 0);

  // // Realizamos la operación personalizada
  // let resultado;
  // if (operacion === "sumar") {
  //   resultado = totalPropiedad + valorPersonalizado;
  //   console.log("Sumar", resultado);
  // } else if (operacion === "restar") {
  //   resultado = totalPropiedad - valorPersonalizado;
  // }

  // return resultado;
};
export function calcularPorcentajeDeTotal(total, porcentaje) {
  if (!total || !porcentaje) return "";
  const resultado = (parseFloat(total) * parseFloat(porcentaje)) / 100;
  return isNaN(resultado) ? "" : resultado;
}
export function calcularValorPorcentual (valor, total) {
  if (!valor || !total) {
    return "";
  }
  if (typeof total === "string" ) {
    total = parseFloat(total);
  }
  if (typeof valor === "string") {
    valor = parseFloat(valor);
  }
  const porcentaje = (valor / total) * 100;
  return parseFloat(porcentaje.toFixed(2));
};
export function sumaDeTotales (obj) {
  return obj.reduce((acc, curr) => acc + (curr.total || 0), 0);
};
export function recaulcularValores (campo, values, setCustomFieldValue) {
  const {
    tipoCom,
    comisionTotal,
    comisionLae,
    porLae,
    opCompartida,
    total,
    trabajadores_hojas,
    participantes
  } = values;
  
  if(campo == "total"){
    if(total > 0){
      let newComisinTotal = calcularPorcentajeDeTotal(total, tipoCom);
      setCustomFieldValue("total", total);
      setCustomFieldValue("comisionTotal", newComisinTotal);
      if(opCompartida){
        let newComisionLae = calcularPorcentajeDeTotal(newComisinTotal, porLae);
        setCustomFieldValue("comisionLae", newComisionLae);
      }
    };
  }
  if(campo == "tipoCom" && opCompartida){
    let newComisionLae = calcularPorcentajeDeTotal(comisionTotal, porLae);
    setCustomFieldValue("comisionLae", newComisionLae);
  }
  if(campo == "comisionTotal" && opCompartida){
    let newComisionLae = calcularPorcentajeDeTotal(comisionTotal, porLae);
    setCustomFieldValue("comisionLae", newComisionLae);
  }
  if(campo == "participantes"){
    Object.keys(values).forEach(key =>{
      let ArrayValues = values[key];
      if(Array.isArray(ArrayValues)){
        ArrayValues.forEach((item, index)=>{
          if (item && item.porcentaje) {
            if(opCompartida && comisionLae > 0 ){
              const totalPorIndice = calcularPorcentajeDeTotal(comisionLae, item.porcentaje);
              setCustomFieldValue(`${key}[${index}].total`, totalPorIndice);
            } else {
              const totalPorIndice = calcularPorcentajeDeTotal(comisionTotal, item.porcentaje);
              setCustomFieldValue(`${key}[${index}].total`, totalPorIndice);
            }
          } 
        });
      }
    });
    const totalSumado = Object.keys(values)
      .filter((key) => Array.isArray(values[key]))
      .reduce((sum, key) => sum + sumaDeTotales(values[key]), 0);
      const totalComisionNeta = comisionLae
        ? comisionLae - totalSumado
        : comisionTotal - totalSumado;
      setCustomFieldValue("totalComisionNeta", totalComisionNeta);
  }
}


  
import React, { useEffect, useState } from "react";
import {
    destinationCreate,
    destinationDetails,
    destinationUpdate,
} from "../../services";
import { useParams, useNavigate } from "react-router-dom";
import ButtonPermission from "../../utilities/ButtonPermission";
import * as Yup from 'yup';
import { useFormValidation } from "../../utilities/formValidator";
import { NoteAdd } from '@mui/icons-material';
import {
    TextField, Box
} from "@mui/material";
import { SnackbarUtilities } from "../../utilities";


function DestinosForm() {
    const { id: destinos_id } = useParams();

    const fetchLanguages = async () => {
        try {
            const response = await destinationDetails(destinos_id);  
            console.log(response);
                      
            setValues({
                destination: response.data.data.destination,
            }); 
            setEditMode(true);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (destinos_id) fetchLanguages();
    }, []);

    const [editMode, setEditMode] = useState(false);
    const navigate = useNavigate();

    const initialValues = {
        destination: '',
    };
    const validationSchema = Yup.object().shape({
        destination: Yup.string().required('Campo obligatorio'),
    });
    const { values, errors, touched, handleChange, handleReset, handleBlur, handleSubmit, setCustomFieldValue, setValues } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        nullConverter: true,
        onSubmit: async (values, modifiedFields) => {
            if (values) {
                try {
                    // Llamar a la función para crear el artículo usando FormData
                    const response = editMode ? await destinationUpdate(destinos_id, values) : await destinationCreate(values);
                    // console.log(response);

                    if (response.status === 201) {
                        SnackbarUtilities.success("Destino creado correctamente!!");
                        setTimeout(() => {
                            navigate(`/Destinos`);

                        }, 2000);
                    }
                    else if (destinos_id && response.status === 200) {
                        SnackbarUtilities.success("Destino Editado correctamente!!");
                        setTimeout(() => {
                            navigate(`/Destinos`);

                        }, 2000);
                    }
                    else {
                        SnackbarUtilities.error("Ha habido un error, intente más tarde");
                    }
                } catch (error) {
                    console.error("Error al crear el Destino:", error);
                    SnackbarUtilities.error("Ha habido un error, intente más tarde");
                }

            } else {
                SnackbarUtilities.error("Error en algun validador del campo");
            }
        },
    });

    return (
        <div className="container">

            <div className="top-line">
                <div style={{ textAlign: "start" }}>
                    <h3>{editMode ? 'Editar Destinos' : 'Crear Destinos'}</h3>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", flex: 0.5, justifyContent: "end" }}>
                    <ButtonPermission variant="contained" type="submit" endIcon={<NoteAdd />}
                        action={editMode ? "edit" : "create"}
                        resource="Destinos"
                        sx={{
                            color: '#c4af6c',
                            backgroundColor: '#33383b',
                            height: 40,
                            marginBottom: 1,
                            borderColor: 'transparent',
                            '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                        }}>
                        Guardar
                    </ButtonPermission>
                </div>
                {
                    (process.env.NODE_ENV == "development" && Object.keys(errors).length > 0) &&
                    <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                        {JSON.stringify(errors)}
                    </div>
                }

                <div className="middleline">
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: { sm: '1fr' },
                                gap: 2,
                            }}
                        >
                            <div >
                                <TextField
                                    fullWidth
                                    label="Nombre del Destino"
                                    name="destination"
                                    margin="normal"
                                    size="small"
                                    sx={inputStyles}
                                    value={values.destination}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.destination && Boolean(errors.destination)}
                                    helperText={touched.destination && errors.destination}
                                />
                            </div>
                        </Box>
                    </div>
                </div>
            </form>

        </div>
    );
}
const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 0,
    marginTop: 0
};

export default DestinosForm;

import { useState, useContext, useEffect } from "react";
import { listClientes } from "../services";
function useClientes() {
    const [limite, setLimite] = useState(0);
    const [data, setData] = useState({
        meta: {},
        rows: [],
    });
    const [loading, setLoading] = useState(true);
    const [dataCustomNames, setDataCustomNames] = useState(null);

    const customNames = [
        'ID', 'Nacionalidad', 'Estado Civil', 'tipo_documento', 'NIF', 'nomconyuge', 'tipo_doc_conyuge', 'idConyuge', 'rep_legal', 'tel_rep_legal','email_rep_legal','Nombre', 'Apellidos', 'Dirección',
        'CP','Provincia', 'Teléfono', 'Email','Tipo','CIF/DNI',"Nombre Comercial","R. Social"];
    const formatData = (data) => {       
        return data.map((row) => (
            {
                id: row.id,
                nacionalidad: row.nacionalidad,
                estado_civil: row.estado_civil,
                tipo_documento: row.tipo_documento,
                numero_identificacion: row.numero_identificacion,
                nomconyuge: row.nomconyuge,
                tipo_doc_conyuge: row.tipo_doc_conyuge,
                idConyuge: row.idConyuge,
                rep_legal: row.rep_legal,
                tel_rep_legal: row.tel_rep_legal,
                email_rep_legal: row.tel_rep_legal,
                nombre:  row.reg_generales?.nombre,
                apellidos: row.reg_generales?.apellidos,
                direccion: row.reg_generales?.direccion,
                cp: row.reg_generales?.cp,
                provincia:row.reg_generales?.provincia,
                // telefono: row.reg_generales?.telefono1,
                telefono: (row.nacionalidad == "ES" ) ? row.reg_generales?.telefono1 : row.tel_rep_legal,
                // email: row.reg_generales?.email1,
                email: (row.nacionalidad == "ES" ) ? row.reg_generales?.email1 : row.email_rep_legal, 
                tipo:row.reg_generales?.tipo?.replace(/_/g, ' '),
                nif:row.reg_generales?.nif,
                nombre_comercial:row.reg_generales?.nombre_comercial,
                razon_social:row.reg_generales?.razon_social
            }));
    };
    const customNameDataTable = (formattedData) => {
        // Obtener las claves del primer objeto para asignar key y label
        const keys = Object.keys(formattedData[0] || {});

        // Retornar un array de objetos con key y label
        return keys.map((key, index) => ({
            key,  // La clave original
            label: customNames[index] || key  // El nombre personalizado o la clave si no hay un nombre en customNames
        }));
    }
    const fetchData = async ({ offset, limit, filtros, orderBy, order }) => {
        setLimite(limit);
        let payload;

        if (orderBy && order) {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit), orderBy: `id,${order}` },
                filtros
            };
        } else {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit) },
                filtros
            };
        }
        try {
            setLoading(true);
            let response = await listClientes(payload);
            // console.log(response);

            const { meta, data } = response;
            setData({
                meta,
                rows: formatData(data),
            })
            const formattedData = formatData(data);  // Formatear los datos
            // console.log(formattedData);
            
            const customData = customNameDataTable(formattedData);  // Personalizar los nombres
            setDataCustomNames(customData);

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    return {
        data,
        loading,
        fetchData,
        limite,
        dataCustomNames
    };
};

export default useClientes;

import { useState, useEffect } from "react";
import { Drawer, Box, Typography, Checkbox, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DataTable from "../Tables/DataTable";
import { FiltroContactosDrawer } from "../Filtros";
import { useFormValidation } from "../../utilities/formValidator";
import { useClientes } from "../../hooks";
import * as Yup from "yup";
import { dynamicTableHeader } from "../../utilities/transformHeaders";

const HojaDrawerComprador = ({ open, setOpen, values, setCustomFieldValue }) => {
  const { loading, data, fetchData, dataCustomNames } = useClientes();
  const [currentPage, setCurrentPage] = useState(1);
  const [filtros, setFiltros] = useState({ tipo: 'Cliente'});

  const [initialValues, setInitialValues] = useState({
    id_sucursal: null,
    tipo: "Cliente",
    nif: "",
    buscador: "",
  });
  const validationSchema = Yup.object({
    id_sucursal: Yup.number().nullable(),
    nif: Yup.string().notRequired().nullable(),
    buscador: Yup.string().notRequired().nullable(),
  });
  const extraHeader = [
    {
      item: (name) => {
        return (
          <Checkbox
            sx={CheckboxStyles}
            onChange={(e) => {
              handleCustomChange(e, name);
            }}
            checked={isCheckched(values.clientes_hojas, name.id)}
          />
        );
      },
      position: 1,
      titleHeader: "",
      nameColumn: "",
      order: "disable",
    },
  ];

  const handleCustomChange = (event, row) => {
    const { name, value, type, checked } = event.target;
    console.log(row);
    const clienteValor = {
      id_cliente:row.id,
      rol:(open.comprador == true && open.vendedor == false) ? "COMPRADOR" : "VENDEDOR",
      nombre: row.nombre,
      apellidos: row.apellidos,
      numero_identificacion:row.numero_identificacion,
      nombre_comercial: row.nombre_comercial,
    }
    console.log(clienteValor)
    if (type == "checkbox" && checked == false) {      
      setCustomFieldValue(
        "clientes_hojas",
        values.clientes_hojas.filter((prop) => prop.id_cliente !== row.id)
      );
    } else {
      // const deleteExampleItem = [...values.comprador_hojas,clienteValor].filter((item) => item.id_cliente !== "example");
      setCustomFieldValue("clientes_hojas", [...values.clientes_hojas,clienteValor]);
    }

  };

  const isCheckched = (propiedades, elId) => {
    // console.log(propiedades, elId);
    
    return propiedades.some(
      (prop) => prop.id_cliente === elId || prop.id_cliente === elId
    );
  };

  useEffect(() => {
    if (values.sucursal_raw_id) {
      
      setFiltros((prevState) => ({
        ...prevState,
        id_sucursal: values.sucursal_raw_id,
      }));
    }
  }, [values.sucursal_raw_id]);

  const {
    values: submitValues,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setCustomFieldValue: setFiltrosValue,
    setValues,
    handleReset,
  } = useFormValidation({
    initialValues,
    validationSchema,
    onSubmit: async (submitValues) => {
      console.log(submitValues);
      const { nif, buscador, tipo } = submitValues;

      setFiltrosValue("filtros", {
        id_sucursal: values.sucursal_raw_id,
        tipo: tipo,
        numero_identificacion: nif,
        buscador: buscador,
      });

      await fetchData({
        offset: currentPage,
        limit: 1000,
        filtros: {
          id_sucursal: values.sucursal_raw_id,
          tipo: tipo,
          numero_identificacion: nif,
          buscador: buscador,
        },
      });
    },
  });
  const handleClose = () => {
    setOpen((prevState) => ({ ...prevState, comprador: false })); // Actualiza el estado de forma inmutable
  };
  return (
    <>
      <Drawer
        anchor="right"
        open={open.comprador}
        onClose={handleClose}
        sx={{ zIndex: 2000 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <div className="container">
          <Box component="section" sx={{ p: 2 }}>
            <Typography variant="h3" gutterBottom>
              Comprador
            </Typography>
            <Typography variant="p" gutterBottom>
              Debes Seleccionar un Comprador
            </Typography>
          </Box>
        </div>
        <FiltroContactosDrawer
            handleChange={handleChange}
            handleBlur={handleBlur}
            filtros={filtros}
            values={values}
            touched={touched}
            errors={errors}
            setFiltros={setFiltros}
            handleSubmit={handleSubmit}
        />
        <div className="middleline">
          <DataTable
            data={data}
            headers={dynamicTableHeader({
              headerArrayData: dataCustomNames,
              showJustThis: [
                "NIF",
                "Nombre",
                "Apellidos",
                "R. Social",
                "Nombre Comercial",
                "Teléfono",
                "Email",
              ],
              addNewCustomHeader: extraHeader,
            })}
            filtros={filtros}
            isLoading={loading}
            fetcher={fetchData}
            mensaje={"No hay clientes disponibles"}
          />
        </div>
      </Drawer>
    </>
  );
};

const CheckboxStyles = {
  "&.Mui-checked": {
    color: "#c4af6c",
  },
  "& .MuiSvgIcon-root": {
    color: "#33383b",
    fontSize: 28,
  },
  "&:hover": {
    backgroundColor: "rgba(196, 175, 108, 0.04)",
  },
};

export default HojaDrawerComprador;

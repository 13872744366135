import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import { useFormValidation } from "../../utilities/formValidator";
import {  GetLanguageFlags, SnackbarUtilities } from "../../utilities";
import {  NoteAdd } from '@mui/icons-material';
import {
    TextField, Box, MenuItem,
} from "@mui/material";
import ButtonPermission from "../../utilities/ButtonPermission";
import {  articleCreate, articleDetails, articleUpdate, listDestinations, listLanguages, listsCategories } from "../../services";
import {  useParams, useNavigate } from "react-router-dom";
import provinces from "@doncicuto/es-provinces";
import DragNDrop from "../../components/DragNDrop/DragNDrop";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getUrlFromFile, getUrlFromString } from "../../utilities/img_url";
import { TrashFill } from "react-bootstrap-icons";
import { getCurrentDateIso } from "../../utilities/dateFormat";


export default function ArticuloForm() {

    const { id: article_id } = useParams();
    const [editMode, setEditMode] = useState(false);
    const [provinceSelected, setProvinceSelected] = useState(null);
    const navigate = useNavigate();


    const [categories, setCategories] = useState([]);
    const [destinations, setDestinations] = useState([]);
    const [imageFile, setImageFile] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [data, setData] = useState({});

    // console.log(article_id);

    const initialValues = {
        title: '',
        category_id: '',
        destination_id: '',
        img: '',
        fechaPubli: getCurrentDateIso(),
        translations: [
            {
                id_idioma: null,
                title: '',
                slug: '',
                content: null
            }
        ],
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Campo obligatorio'),
        category_id: Yup.string().required('Campo obligatorio'),
        destination_id: Yup.string().required('Campo obligatorio'),
        img: Yup.mixed()
            .test(
                'fileDimensions',
                'La imagen debe tener un mínimo de 300x300 píxeles.',
                (value) =>
                    new Promise((resolve, reject) => {
                        // Si el valor no es un archivo (File), devuélvelo como válido
                        if (!value || typeof value !== 'object' || !(value instanceof File)) {
                            console.log("Valor no es un archivo, devolviendo válido:", value);
                            return resolve(true); // Devuelve `true` para que pase la validación
                        }

                        // Si es un archivo, valida las dimensiones
                        const reader = new FileReader();
                        reader.onload = (event) => {
                            const img = new Image();
                            img.onload = () => {
                                if (img.width >= 400 && img.height >= 260) {
                                    resolve(true);
                                } else {
                                    resolve(false);
                                }
                            };
                            img.onerror = () => resolve(false);
                            img.src = event.target.result;
                        };
                        reader.readAsDataURL(value);
                    })
            )
            .nullable(),
        fechaPubli: Yup.string().nullable(),
        translations: Yup.array().of(
            Yup.object().shape({
                id_idioma: Yup.string().nullable(),
                title: Yup.string().nullable(),
                slug: Yup.string().nullable(),
                content: Yup.string().nullable(),
            })
        ),
    });

    // Usa la función de validación personalizada
    const { values, errors, touched, handleChange, handleReset, handleBlur, handleSubmit, setCustomFieldValue, setValues } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        nullConverter: true,
        onSubmit: async (values, modifiedFields) => {
            if (values) {
                // console.log(values);

                // Actualizar translations con id_idioma correspondiente
                const updatedTranslations = values.translations.map((translation, index) => ({
                    ...translation,
                    id_idioma: languages[index]?.id || translation.id_idioma,
                }));

                // Crear un FormData para enviar los datos
                const dataToSend = new FormData();

                // Añadir los campos al FormData
                dataToSend.append("title", values.title);
                dataToSend.append("category_id", values.category_id);
                dataToSend.append("destination_id", values.destination_id);
                dataToSend.append("img", values.img); // Supongo que imageFile es el archivo de la imagen
                dataToSend.append("fechaPubli", values.fechaPubli); // Supongo que imageFile es el archivo de la imagen
                dataToSend.append("translations", JSON.stringify(updatedTranslations));
                try {
                    // Llamar a la función para crear el artículo usando FormData
                    const response = editMode ? await articleUpdate(article_id, dataToSend) : await articleCreate(dataToSend);

                    if (response.status === 201) {
                        SnackbarUtilities.success("Artículo creado correctamente!!");
                        setTimeout(() => {
                            navigate(`/Articulos`);

                        }, 2000);
                    }
                    else if (article_id && response === 200) {
                        SnackbarUtilities.success("Artículo Editado correctamente!!");
                        setTimeout(() => {
                            navigate(`/Articulos`);

                        }, 2000);
                    }
                    else {
                        SnackbarUtilities.error("Ha habido un error, intente más tarde");
                    }
                } catch (error) {
                    console.error("Error al crear el artículo:", error);
                    SnackbarUtilities.error("Ha habido un error, intente más tarde");
                }

            } else {
                SnackbarUtilities.error("Error en algun validador del campo");
            }
        },
    });

    const fetchCategories = async () => {
        try {
            const response = await listsCategories({ offset: 1, limit: 1000 });
            //   console.log(response)
            setCategories(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchDestinations = async () => {
        try {
            const response = await listDestinations({ offset: 1, limit: 1000 });

            setDestinations(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };
    const fetchLanguages = async () => {
        try {
            const response = await listLanguages({ offset: 1, limit: 1000 });
            // console.log(response.data);
            setLanguages(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchArticle = async () => {
        // console.log("sada");
        const response = await articleDetails(article_id);
        // console.log(response);
        const propertiesToRemove = ['language', 'creator', 'editor'];

        const filteredTranslations = response.data.data.translations.map((obj) => {
            propertiesToRemove.forEach((prop) => delete obj[prop]);
            return obj;
        });
        setValues({
            title: response.data.data.title,
            category_id: response.data.data.category.id,
            destination_id: response.data.data.destination_id,
            img: response.data.data.img,
            fechaPubli: response.data.data.fechaPubli && new Date(response.data.data.fechaPubli).toISOString().split('T')[0] || '',
            translations: filteredTranslations,
        });

        setImageFile(getUrlFromString(response.data.data.img, response));
        setEditMode(true);
    }

    useEffect(() => {
        fetchCategories();
        fetchDestinations();
        fetchLanguages();
        if (article_id) {
            fetchArticle();
        }

    }, [])

    const handleCustomChange = (event) => {
        const { name, value, type, checked } = event.target;
        // console.log(name, value, type, checked);

        if (name == "category_id") {
            const selectedProvince = provinces.filter((p) => p.name == value);
            if (selectedProvince) {
                // console.log(selectedProvince[0].code);
                setProvinceSelected(selectedProvince[0]?.code);
                handleChange(event)
            }
        }

        handleChange(event);

    }

    return (
        <div className="container">
            {/* <BreadCrumb /> */}
            <div className="top-line">
                <div style={{ textAlign: "start" }}>
                    <h3>{editMode ? 'Editar Articulo' : 'Crear Articulo'}</h3>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", flex: 0.5, justifyContent: "end" }}>
                    <ButtonPermission variant="contained" type="submit" endIcon={<NoteAdd />}
                        action="edit"
                        resource="Articulos"
                        sx={{
                            color: '#c4af6c',
                            backgroundColor: '#33383b',
                            height: 40,
                            marginBottom: 1,
                            borderColor: 'transparent',
                            '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                        }}>
                        Guardar
                    </ButtonPermission>
                </div>
                {
                    (process.env.NODE_ENV == "development" && Object.keys(errors).length > 0) &&
                    <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                        {JSON.stringify(errors)}
                    </div>
                }
                <div className="middleline">
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: { sm: '1fr 1fr 1fr 1fr' },
                                gap: 2,
                            }}
                        >
                            <div >
                                <TextField
                                    fullWidth
                                    label="Título del Artículo"
                                    name="title"
                                    margin="normal"
                                    size="small"
                                    sx={inputStyles}
                                    value={values.title}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.title && Boolean(errors.title)}
                                    helperText={touched.title && errors.title}
                                />
                            </div>
                            <div >
                                <TextField
                                    id="demo-simple-select-label"
                                    select
                                    label="Categoría"
                                    name="category_id"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.category_id}
                                    size="small"
                                    onChange={handleCustomChange}
                                    onBlur={handleBlur}
                                    error={touched.category_id && Boolean(errors.category_id)}
                                    helperText={touched.category_id && errors.category_id}
                                >
                                    {categories.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div >
                                <TextField
                                    id="demo-simple-select-label"
                                    select
                                    label="Destinos"
                                    name="destination_id"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.destination_id}
                                    size="small"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.destination_id && Boolean(errors.destination_id)}
                                    helperText={touched.destination_id && errors.destination_id}
                                >
                                    {destinations.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.destination}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div >
                                <TextField
                                    fullWidth
                                    label="Fecha Publicación"
                                    name="fechaPubli"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    size="small"
                                    sx={inputStyles}
                                    value={values.fechaPubli}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.fechaPubli && Boolean(errors.fechaPubli)}
                                    helperText={touched.fechaPubli && errors.fechaPubli}
                                />
                            </div>
                            {imageFile ? (
                                <div
                                    style={{
                                        position: "relative",
                                        width: "100%",
                                        height: "400px",
                                        marginBottom: "30px",
                                        marginTop: "30px",
                                        gridColumn: 'span 4',
                                        overflow: "hidden", // Asegura que la imagen no se salga del contenedor
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "absolute",
                                            right: 10,
                                            top: 10,
                                            cursor: "pointer",
                                            zIndex: 1 // Asegura que el icono esté encima de la imagen
                                        }}
                                        onClick={() => {
                                            setImageFile(null);
                                            setCustomFieldValue('img', null);
                                        }}
                                    >
                                        <TrashFill color="red" height={22} width={22} />
                                    </div>
                                    <img
                                        src={imageFile}
                                        alt="imagen"
                                        style={{
                                            objectFit: "contain", // Para que la imagen cubra todo el espacio sin deformarse
                                            height: "100%", // Usa toda la altura del contenedor
                                            width: "100%", // Usa todo el ancho del contenedor
                                            position: "absolute", // Para asegurarse de que ocupe todo el contenedor sin restricciones
                                            top: 0, // Alinear la imagen al borde superior del contenedor
                                            left: 0 // Alinear la imagen al borde izquierdo del contenedor
                                        }}
                                    />
                                    {touched.img && errors.img && values.img && (
                                        <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                                            {errors.img}
                                        </div>
                                    )}
                                </div>

                            ) : (
                                <div style={{ gridColumn: 'span 4' }}>
                                    <>
                                        <DragNDrop
                                            onUpload={(files) => { setCustomFieldValue('img', files); setImageFile(getUrlFromFile(files)) }}
                                            count={1}
                                            formats={["jpg", "png", "svg", "jpeg"]}
                                            label={<p variant="caption">Imagen Artículo</p>}
                                            title='"Suelta un archivo aqui..."'
                                            openDialogOnClick={true}
                                            style={{ marginBottom: "20px" }}
                                            onBlur={handleBlur}
                                            error={touched.img && Boolean(errors.img)}
                                            helperText={touched.img && errors.img}
                                        />
                                    </>

                                </div>
                            )}

                            {languages.length &&
                                languages.map((lang, index) => (
                                    <div style={{ gridColumn: 'span 4' }}>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ArrowDownwardIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                {/* <Typography>Accordion 1</Typography> */}
                                                <GetLanguageFlags countryCode={lang.iso_code} />
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: { sm: '1fr 1fr' },
                                                    gap: 2,
                                                }}>

                                                    <input
                                                        type="hidden"
                                                        name={`translations[${index}].id_idioma`}
                                                        value={lang.id}
                                                    />

                                                    <div >
                                                        <TextField
                                                            fullWidth
                                                            label="Título del Artículo"
                                                            name={`translations[${index}].title`}
                                                            margin="normal"
                                                            size="small"
                                                            sx={inputStyles}
                                                            value={values.translations[index]?.title || ''}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={touched.translations?.[index]?.title && Boolean(errors.translations?.[index]?.title)}
                                                            helperText={touched.translations?.[index]?.title && errors.translations?.[index]?.title}
                                                        />
                                                    </div>
                                                    <div >
                                                        <TextField
                                                            fullWidth
                                                            label="Slug"
                                                            name={`translations[${index}].slug`}
                                                            margin="normal"
                                                            size="small"
                                                            sx={inputStyles}
                                                            value={values.translations[index]?.slug || ''}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={touched.translations?.[index]?.slug && Boolean(errors.translations?.[index]?.slug)}
                                                            helperText={touched.translations?.[index]?.slug && errors.translations?.[index]?.slug}
                                                        />
                                                    </div>
                                                    <div style={{ gridColumn: 'span 3' }}>
                                                        <>
                                                            <Typography variant="h6" sx={{ my: 2 }}>Contenido</Typography>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                data={values.translations[index]?.content}
                                                                config={{
                                                                    language: lang.iso_code ? lang.iso_code.toLowerCase() : "es",
                                                                    toolbar: [
                                                                        "heading",
                                                                        "|",
                                                                        "bold",
                                                                        "italic",
                                                                        "link",
                                                                        "bulletedList",
                                                                        "numberedList",
                                                                        "|",
                                                                        "outdent",
                                                                        "indent",
                                                                        "|",
                                                                        "blockQuote",
                                                                        "mediaEmbed",
                                                                        "undo",
                                                                        "redo",
                                                                    ],
                                                                }}

                                                                onChange={(event, editor) => {
                                                                    const text = editor.getData();
                                                                    setCustomFieldValue(`translations[${index}].content`, text)
                                                                }}
                                                            />
                                                        </>
                                                    </div>
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                ))}
                        </Box>
                    </div>
                </div>
            </form>
        </div>
    )
}

const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 0,
    marginTop: 0
};